import cx from 'classnames';
import { FC, PropsWithChildren, ReactNode, SyntheticEvent, useEffect } from 'react';

import useTracking from '@zen/utils/hooks/useTracking';
import { createTrackingEvent, createTrackingParentAttribute } from '@zen/utils/tracking';

import { IconButton } from '../Button';
import { Headline } from '../Typography';
import BaseModal from './BaseModal';

interface Props {
  className?: string;
  closeButtonVisible?: boolean;
  closeOnClickAway?: boolean;
  control?: ReactNode;
  footer?: ReactNode;
  isOpen: boolean;
  modalOverflowY?: OverflowYClassname;
  onClose?: () => void;
  tagline?: string;
  title: ReactNode;
  variant?: 'default' | 'compact';
  width?: 'full' | 'medium' | 'wider';
}

export type OverflowYClassname = 'auto' | 'visible' | 'scroll';

const overflowYClasses: Record<OverflowYClassname, string> = {
  auto: 'overflow-y-auto js-element-scrollable',
  visible: 'overflow-y-visible',
  scroll: 'overflow-y-scroll js-element-scrollable'
};

export const displayName = 'Design System/Modal';

const Modal: FC<PropsWithChildren<Props>> = (props) => {
  const {
    className,
    closeButtonVisible = true,
    isOpen,
    modalOverflowY = 'auto',
    onClose,
    closeOnClickAway = true,
    title,
    footer,
    children,
    control,
    tagline,
    width = 'medium',
    variant = 'default',
    ...rest
  } = props;

  const { trackEvent } = useTracking();

  const handleClose = (event: SyntheticEvent | MouseEvent | KeyboardEvent): void => {
    const trackingEvent = createTrackingEvent(displayName, 'close', event);

    trackEvent(trackingEvent);
    onClose?.();
  };

  // when the modal is opened we need to disable the scrolling
  useEffect(() => {
    const { classList } = document.body;

    const overflowClass: string = 'overflow-hidden';

    if (isOpen) {
      classList.add(overflowClass);
    } else {
      classList.remove(overflowClass);
    }

    return () => {
      classList.remove(overflowClass);
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  const modalContainerClassNames: string = cx({
    'w-[40rem]': width === 'medium',
    'w-[48rem]': width === 'wider',
    'w-[90vw]': width === 'full'
  });

  const headerClassNames: string = cx(
    {
      'pb-4': !control
    },
    'py-4 px-6 border-b border-solid border-grey-lighter space-y-2'
  );

  const contentClassNames: string = cx({ 'p-6 mb-[4.5rem]': variant === 'default' }, overflowYClasses[modalOverflowY]);

  const handleClickAway = () => {
    if (closeOnClickAway) {
      onClose?.();
    }
  };

  return (
    <BaseModal
      contentClassName={modalContainerClassNames}
      data-component="modal"
      data-testid="modal"
      isOpen={isOpen}
      onClickAway={handleClickAway}
      {...createTrackingParentAttribute('modal')}
      {...rest}
    >
      <div className={headerClassNames}>
        <div className="flex items-center justify-between">
          <div data-testid="modal-header">
            <Headline className="mr-4" level={3}>
              {title}
            </Headline>
            {tagline && <div className="text-sm leading-normal text-grey-base">{tagline}</div>}
          </div>
          {closeButtonVisible && (
            <IconButton icon="zicon-close" onClick={handleClose} size="medium" title="Close" variant="ghost" />
          )}
        </div>
        {control}
      </div>
      <div className={contentClassNames} data-testid="modal-content">
        {children}
      </div>
      {footer && (
        <div className="fixed bottom-0 left-0 right-0 px-6 py-4 bg-white border-t border-solid rounded-b border-grey-lighter">
          {footer}
        </div>
      )}
    </BaseModal>
  );
};

export type { Props as ModalProps };
export default Modal;
