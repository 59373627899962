import 'react-toastify/dist/ReactToastify.css';

import type { FC, ReactNode } from 'react';
import { Slide, toast, ToastContainer, ToastOptions } from 'react-toastify';

import { defaultErrorMessage } from '@zen/utils/validation';

import CloseButton from './CloseButton';
import NotificationContext from './NotificationContext';
import ToastMessage from './ToastMessage';
import type { NotificationAction } from './types';

const ACTIONABLE_TOAST_DELAY: number = 10000;
const NON_ACTIONABLE_TOAST_DELAY: number = 5000;

interface Props {
  children: ReactNode;
}

const NotificationProvider: FC<Props> = ({ children }) => {
  const createConfig = (action?: NotificationAction): ToastOptions => ({
    autoClose: action ? ACTIONABLE_TOAST_DELAY : NON_ACTIONABLE_TOAST_DELAY
  });

  const addSuccess = (message: string, action?: NotificationAction): string | number => {
    return toast.success(<ToastMessage action={action} message={message} />, createConfig(action));
  };

  const addError = (message: string = defaultErrorMessage, action?: NotificationAction): string | number => {
    return toast.error(<ToastMessage action={action} message={message} />, createConfig(action));
  };

  const toastBackgroundClassNames: Record<string, string> = {
    success: 'bg-grey-dark',
    error: 'bg-red-dark',
    info: 'bg-azure-base',
    warning: 'bg-orange-base',
    default: 'bg-grey-base'
  };

  const toastClassNames: string = 'flex items-baseline rounded cursor-pointer px-6 py-4 mb-3';
  const toastContainerClassNames: string = '!w-auto !max-w-[500px] fixed flex flex-col items-center';

  return (
    <NotificationContext.Provider value={{ addSuccess, addError }}>
      {children}
      <ToastContainer
        autoClose={NON_ACTIONABLE_TOAST_DELAY}
        bodyClassName="!p-0"
        className={toastContainerClassNames}
        closeButton={CloseButton}
        hideProgressBar={true}
        icon={false}
        position="bottom-center"
        toastClassName={(toastContext) => `${toastBackgroundClassNames[toastContext?.type || 'default']} ${toastClassNames}`}
        transition={Slide}
      />
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
