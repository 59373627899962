import pluralize from 'pluralize';
import type { FC, ReactNode } from 'react';

import { useDefaultUserGroup } from '@zen/Accounts/hooks/useDefaultUserGroup';
import { Form, FormButtons, FormInstance, FormMultiText, FormRadioGroup } from '@zen/Components';
import { Button, Modal } from '@zen/DesignSystem';
import useAccount from '@zen/utils/hooks/useAccount';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import FormBusinessUnitsSelect from '../../components/FormAccountBusinessUnitsSelect';
import FormAccountUserGroupsSelect from '../../components/FormAccountUserGroupsSelect';
import { useInviteUsersMutation } from '../../graphql/inviteUsers.generated';
import validationSchema from './addMemberModal.validation';
import { businessUnitsAssignmentOptions } from './helpers';
import type { MemberFormValues } from './types';

interface Props {
  accountHasBusinessUnits: boolean;
  accountId: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const AddMemberModal: FC<Props> = ({ accountHasBusinessUnits, accountId, isOpen, onClose, onSuccess }) => {
  const [inviteUsers] = useInviteUsersMutation();
  const { addError, addSuccess } = useNotification();
  const { userProfile } = useAccount();
  const { userGroup } = useDefaultUserGroup(accountId);

  const renderFormButtons = ({ isSubmitting }: FormInstance<MemberFormValues>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Add member">
      <Button data-testid="cancel-button" onClick={onClose} variant="ghost">
        Cancel
      </Button>
    </FormButtons>
  );

  const handleSubmit = async (values: MemberFormValues): Promise<IOkOrErrorResult> => {
    const { businessUnits, businessUnitsAssigments, emails, role } = values;
    const { firstName, lastName, registeredCompanyName } = userProfile;

    return performFormMutation({
      mutationFn: () =>
        inviteUsers({
          variables: {
            input: {
              accountId,
              emails,
              businessUnits: businessUnitsAssigments === 'all-business-units' ? [] : businessUnits,
              role,
              invitedBy: {
                accountName: registeredCompanyName,
                userFullName: `${firstName} ${lastName}`
              }
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess(`${pluralize('Member', emails.length)} successfully added.`);
        onSuccess();
      }
    });
  };

  const initialValues: MemberFormValues = {
    emails: [],
    accountId,
    businessUnits: [],
    businessUnitsAssigments: 'all-business-units',
    role: userGroup
  };

  return (
    <Modal closeOnClickAway={false} isOpen={isOpen} onClose={onClose} title="Add member">
      <Form
        formButtons={renderFormButtons}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onSuccess={onClose}
        validationSchema={validationSchema}
      >
        {({ values }: FormInstance<MemberFormValues>) => {
          const isSpecificBusinessUnitsOptionSelected: boolean = values.businessUnitsAssigments === 'specific-business-units';

          return (
            <>
              <FormMultiText
                helperText="Provide multiple emails to invite users in bulk. We cannot send invitations to distribution lists."
                label="Emails"
                name="emails"
                placeholder="name@company.com, name@company.co.uk..."
                separateOn={['Tab', 'Comma', 'Space', 'Enter']}
              />
              <FormAccountUserGroupsSelect accountId={accountId} label="Invite as" name="role" />
              {accountHasBusinessUnits && (
                <FormRadioGroup
                  label="Grant access to:"
                  name="businessUnitsAssigments"
                  noMargin={true}
                  options={businessUnitsAssignmentOptions}
                  radioAlignment="column"
                />
              )}
              {isSpecificBusinessUnitsOptionSelected && (
                <FormBusinessUnitsSelect accountId={accountId} className="mt-4" isOptional={true} name="businessUnits" />
              )}
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

export default AddMemberModal;
export type { Props as AddMemberModalProps };
