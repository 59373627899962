import type { OceanCharge } from '@zen/OperationsSettings';
import type { RateCardCharge } from '@zen/RateCards/reducer';

import type { LocationType } from '../../components/types';
import { getChargeItemApplicability } from '../PortChargeForm/helpers';

export const preparePortChargePayload = (portCharges: OceanCharge[], type: LocationType): RateCardCharge[] => {
  const locationKey: 'fromLocation' | 'toLocation' = type === 'origin' ? 'fromLocation' : 'toLocation';

  return portCharges.map((portCharge: OceanCharge) => {
    const { chargeType, currency, itemName, location, unitPrice } = portCharge;

    return {
      applicability: [getChargeItemApplicability(type)],
      chargeBasis: [],
      cargoOptions: [],
      chargeType,
      currency,
      defaultCharge: portCharge,
      defaultChargeHidden: false,
      itemName,
      unitPrice,
      [locationKey]: location
    };
  });
};
