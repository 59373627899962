import type { TableColumn } from '@zen/DesignSystem';
import type { RateCardCharge } from '@zen/RateCards/reducer';

import {
  chargeTypeColumn,
  getActionsColumn,
  getCargoOptionsColumn,
  getChargeBasisColumn,
  getChargeNameColumn,
  getCurrencyColumn,
  getLocationColumn,
  getUnitPriceColumn
} from '../../components/helpers';
import type { ChargeTableAction } from '../../components/types';

export const getOtherChargeTableColumns = (
  actions: ChargeTableAction,
  isEditable: boolean = false
): TableColumn<RateCardCharge>[] => {
  const { onUpdate } = actions;

  return [
    getLocationColumn('fromLocation', 'Port'),
    ...(isEditable ? [chargeTypeColumn] : []),
    getChargeNameColumn(isEditable, onUpdate),
    getChargeBasisColumn(onUpdate),
    getCargoOptionsColumn(onUpdate),
    getUnitPriceColumn(isEditable, onUpdate),
    getCurrencyColumn(isEditable, onUpdate),
    ...(isEditable ? [getActionsColumn(actions)] : [])
  ];
};
