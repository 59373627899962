import type { FC, MouseEvent, ReactNode } from 'react';

import type { IconName } from '@zen/Styleguide';
import useTracking from '@zen/utils/hooks/useTracking';
import { createTrackingEvent, createTrackingLabelAttribute, createTrackingParentAttribute } from '@zen/utils/tracking';

import Icon from '../../Icon';
import type { PopoverPlacement } from '../../Popover';
import Tooltip from '../../Tooltip';
import StyledButton, { StyledButtonProps } from '../StyledButton';
import { getStyles } from './helpers';
import type { IconButtonSize } from './types';

interface Props extends Omit<StyledButtonProps, 'aria-label'> {
  className?: string;
  delayShow?: number;
  icon: IconName;
  popoverPlacement?: PopoverPlacement;
  size?: IconButtonSize;
  title?: string;
}

export const displayName: string = 'Design System/Icon Button';

const IconButton: FC<Props> = (props) => {
  const {
    className = '',
    disabled = false,
    icon,
    onClick,
    size = 'large',
    title,
    popoverPlacement = 'bottom',
    delayShow = 500,
    type,
    ...rest
  } = props;
  const { buttonClassnames, iconClassnames } = getStyles(size);
  const { trackEvent } = useTracking();

  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    const trackingEvent = createTrackingEvent(displayName, 'click', event);

    trackEvent(trackingEvent);
    onClick?.(event);
  };

  const button: ReactNode = (
    <StyledButton
      aria-label={title}
      className={`${buttonClassnames} ${className}`}
      disabled={disabled}
      onClick={handleClick}
      type={type}
      {...createTrackingParentAttribute('icon-button')}
      {...rest}
    >
      <Icon className={iconClassnames} icon={icon} {...createTrackingLabelAttribute(title || icon)} />
    </StyledButton>
  );

  if (!title) {
    return button;
  }

  return (
    <Tooltip delayShow={delayShow} placement={popoverPlacement} tooltipContent={title}>
      {button}
    </Tooltip>
  );
};

export type { Props as IconButtonProps };

export default IconButton;
