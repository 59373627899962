import type { Option } from '@zen/DesignSystem';

import { ContractIdType, ContractType } from './types';

export const contractTypeMapping: Record<ContractType, string> = {
  [ContractType.BLOCK_SPACE]: 'BLOCK SPACE',
  [ContractType.CUSTOMER_OWNED]: 'CUSTOMER OWNED',
  [ContractType.FAK]: 'FAK',
  [ContractType.INDEX_LINKED]: 'INDEX LINKED',
  [ContractType.NAC]: 'NAC',
  [ContractType.NAC_BASKET]: 'NAC BASKET',
  [ContractType.PREPAID]: 'PREPAID',
  [ContractType.SPOT]: 'SPOT'
};

export const contractTypeOptions: Option<ContractType>[] = Object.entries(contractTypeMapping).map(([value, label]) => ({
  value: value as ContractType,
  label
}));

export const getContractTypeLabel = (contractIdType: ContractIdType): string =>
  (contractIdType?.contractType && contractTypeMapping[contractIdType?.contractType]) || '';
