import type { FC } from 'react';

import { Form, FormButtons, FormInstance, FormSelect } from '@zen/Components';
import { ChargeGroupItemType, FormCostTrackingLocationSelect } from '@zen/CostTracking';
import { Button } from '@zen/DesignSystem';
import { preparePortOptions } from '@zen/RateCards/PortCharges/PortChargeForm';
import { getChargeItemApplicability } from '@zen/RateCards/PortCharges/PortChargeForm/helpers';
import RateCardChargeFormItems from '@zen/RateCards/RateCardForm/components/RateCardChargeFormItems';
import type { RateCardCharge } from '@zen/RateCards/reducer';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import type { LocationType } from '../../components/types';
import { validationSchema } from './haulageCharge.validation';
import { initialValues, preparePayload } from './helpers';
import {
  Applicability,
  type CostTrackingLocation,
  CostTrackingLocationType,
  type HaulageChargeFormInitialValues,
  type HaulageChargeFormValues
} from './types';

interface Props {
  locationType: LocationType;
  onCancel: () => void;
  onSubmit: (values: RateCardCharge[]) => void;
  onSuccess: () => void;
  ports: CostTrackingLocation[];
}

const HaulageChargeForm: FC<Props> = (props) => {
  const { locationType, onSubmit, onSuccess, onCancel, ports } = props;
  const originLabel: string = locationType === 'origin' ? 'Collection' : 'Destination';
  const destinationLabel: string = `Port of ${locationType}`;
  const chargeTypeApplicability: Applicability = getChargeItemApplicability(locationType);

  const handleSubmit = (values: HaulageChargeFormValues): Promise<IOkOrErrorResult> => {
    const haulageChargePayload = preparePayload(values, ports, chargeTypeApplicability);

    onSubmit(haulageChargePayload);

    return Promise.resolve({ ok: {}, error: null });
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<HaulageChargeFormInitialValues>) => {
    return (
      <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Add charges">
        <Button onClick={onCancel} variant="ghost">
          Cancel
        </Button>
      </FormButtons>
    );
  };

  return (
    <Form
      formButtons={renderFormButtons}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      validationSchema={validationSchema}
    >
      {({ values }: FormInstance<HaulageChargeFormValues>) => {
        const { chargeItems } = values;

        return (
          <>
            <div className="grid grid-cols-2 gap-x-4 pr-14">
              <FormCostTrackingLocationSelect
                label={originLabel}
                locationType={CostTrackingLocationType.SHIPPABLE_LOCATION}
                name="fromLocation"
              />
              <FormSelect className="flex-1" label={destinationLabel} name="toLocationId" options={preparePortOptions(ports)} />
            </div>
            <RateCardChargeFormItems
              chargeItems={chargeItems}
              chargeTypeApplicability={chargeTypeApplicability}
              chargeTypeSearchGroups={[ChargeGroupItemType.HAULAGE]}
            />
          </>
        );
      }}
    </Form>
  );
};

export type { Props as HaulageChargeFormProps };

export default HaulageChargeForm;
