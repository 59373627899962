import { isArray, isObject } from 'lodash';

import CurrencySelect from '@zen/Components/CurrencySelect';
import InlineEditableField from '@zen/Components/InlineEditableField';
import InlineNumberInput from '@zen/Components/InlineNumberInput';
import { applicabilityLabelMapping } from '@zen/CostTracking';
import type { TableColumn } from '@zen/DesignSystem';
import type { RateCardCharge } from '@zen/RateCards/reducer';
import type { Nullable } from '@zen/utils/typescript';

import { formatCost } from '../RateCardForm/utils/tableDataFormatting';
import ActionHandleContainer from './ActionHandleContainer';
import CargoOptionsSelect from './CargoOptionsSelect';
import ChargeBasisSelect from './ChargeBasisSelect';
import type {
  Applicability,
  CargoOptionEnum,
  ChargeBasis,
  ChargeLocationType,
  ChargeTableAction,
  CostTrackingLocation,
  Currency,
  CurrencyKey,
  NetworksOrgLoc,
  PriceKey,
  Terminal
} from './types';

const commonWidth: number = 160;

const renderCell = <T extends {} | undefined | null>(value: T) => {
  const hasValue: boolean = isArray(value) ? !!value.length : !!value;

  if (!hasValue) {
    return '-';
  }

  return value;
};

const renderApplicabilities = (applicability: Applicability[]) => {
  return applicability.map((applicabilityItem: Applicability) => applicabilityLabelMapping[applicabilityItem]).join(', ');
};

export const getActionsColumn = (actions: ChargeTableAction): TableColumn<RateCardCharge> => {
  const { onDelete, onVisibilityUpdate } = actions;

  const handleDelete = (index: number) => {
    onDelete?.(index);
  };

  return {
    key: 'actions',
    sortable: false,
    title: '',
    render: (_, charge: RateCardCharge, index: number) => {
      const checkVisibility = (): boolean | undefined => {
        if (!charge.defaultCharge?.id) {
          return undefined;
        }

        return !charge.defaultChargeHidden;
      };

      return (
        <ActionHandleContainer
          isDefaultChargeVisible={checkVisibility()}
          onDelete={() => handleDelete(index)}
          onUpdate={() => onVisibilityUpdate?.(charge.defaultCharge?.id || '', !charge.defaultChargeHidden)}
        />
      );
    },
    width: 60
  };
};

export const getApplicabilityColumn = (title: string): TableColumn<RateCardCharge> => {
  return {
    key: 'applicability',
    sortable: false,
    title,
    width: commonWidth,
    render: (_, { applicability }: RateCardCharge) => {
      return renderApplicabilities(applicability);
    }
  };
};

export const getCargoOptionsColumn = (onItemNameUpdate: ChargeTableAction['onUpdate']): TableColumn<RateCardCharge> => {
  return {
    key: 'cargoOptions',
    sortable: false,
    title: 'Cargo options',
    width: commonWidth,
    render: (_, { cargoOptions }: RateCardCharge, index: number) => {
      const handleChange = (values: Nullable<CargoOptionEnum[]>) => {
        onItemNameUpdate?.(index, { cargoOptions: values || [] });
      };

      return (
        <div className="-ml-3">
          <CargoOptionsSelect onChange={handleChange} value={cargoOptions} variant="inline" />
        </div>
      );
    }
  };
};

export const getChargeBasisColumn = (onItemNameUpdate: ChargeTableAction['onUpdate']): TableColumn<RateCardCharge> => {
  return {
    key: 'chargeBasis',
    sortable: false,
    title: 'Basis',
    width: commonWidth,
    render: (_, { chargeBasis, chargeType }: RateCardCharge, index: number) => {
      const handleChange = (values: Nullable<ChargeBasis[]>) => {
        onItemNameUpdate?.(index, { chargeBasis: values || [] });
      };
      const values: string[] = chargeBasis.map((item: ChargeBasis) => item.id);

      return (
        <div className="-ml-3">
          <ChargeBasisSelect chargeBasisGroup={chargeType.basisGroup} onChange={handleChange} value={values} variant="inline" />
        </div>
      );
    }
  };
};

export const getChargeNameColumn = (
  isEditable: boolean = true,
  onItemNameUpdate: ChargeTableAction['onUpdate']
): TableColumn<RateCardCharge> => {
  return {
    key: 'itemName',
    sortable: false,
    title: 'Charge name',
    width: commonWidth,
    render: (_, { itemName }: RateCardCharge, index: number) => {
      if (!isEditable) {
        return itemName;
      }

      const handleChange = (value: string) => {
        onItemNameUpdate?.(index, { itemName: value || '' });
      };

      return (
        <div className="-ml-3">
          <InlineEditableField name="itemName" onUpdate={handleChange} value={itemName} />
        </div>
      );
    }
  };
};

export const chargeTypeColumn: TableColumn<RateCardCharge> = {
  key: 'chargeType',
  sortable: false,
  title: 'Charge type',
  width: commonWidth,
  render: (_, { chargeType }: RateCardCharge) => {
    return chargeType?.name;
  }
};

export const getCurrencyColumn = (
  isEditable: boolean = true,
  onCurrencyUpdate: ChargeTableAction['onUpdate'],
  currencyKey: CurrencyKey = 'currency',
  title: string = 'Currency'
): TableColumn<RateCardCharge> => {
  return {
    key: currencyKey,
    sortable: false,
    title,
    width: 60,
    render: (_, rateCardCharge: RateCardCharge, index: number) => {
      if (!isEditable) {
        return renderCell(rateCardCharge[currencyKey]);
      }

      const handleChange = (value: Nullable<Currency>) => {
        onCurrencyUpdate?.(index, { [currencyKey]: value });
      };

      return (
        <div className="-ml-3">
          <CurrencySelect
            onChange={handleChange}
            renderMenuInPortal={true}
            size="compact"
            value={rateCardCharge.currency}
            variant="inline"
          />
        </div>
      );
    }
  };
};

export const getLocationColumn = (
  locationKey: ChargeLocationType,
  title: string,
  additionalOptions: Partial<TableColumn<RateCardCharge>> = {}
): TableColumn<RateCardCharge> => {
  return {
    key: locationKey,
    mergeBy: (charge: RateCardCharge) => charge[locationKey]?.id || '',
    sortable: false,
    title,
    render: (_, charge: RateCardCharge) => {
      return charge[locationKey]?.label?.long;
    },
    ...additionalOptions
  };
};

export const getUnitPriceColumn = (
  isEditable: boolean = true,
  onPriceUpdate: ChargeTableAction['onUpdate'],
  priceKey: PriceKey = 'unitPrice'
): TableColumn<RateCardCharge> => {
  return {
    key: priceKey,
    alignment: 'right',
    sortable: false,
    title: 'Unit price',
    width: commonWidth,
    render: (_, rateCardCharge: RateCardCharge, index: number) => {
      if (!isEditable) {
        return renderCell(rateCardCharge[priceKey]);
      }

      return (
        <InlineNumberInput
          onUpdate={(updatedUnitPrice: number) => onPriceUpdate?.(index, { unitPrice: updatedUnitPrice })}
          size="compact"
          value={rateCardCharge[priceKey] || null}
        />
      );
    }
  };
};

export const defaultChargeDetailsColumn: TableColumn<RateCardCharge> = {
  key: 'defaultChargeDetails',
  sortable: false,
  title: 'Default price',
  width: commonWidth,
  render: (_, { currency, defaultCharge, unitPrice }: RateCardCharge) => {
    const value: Nullable<string> = defaultCharge?.id ? formatCost(unitPrice, currency) : null;

    return renderCell(value);
  }
};

export const prepareLocation = (location: Terminal | NetworksOrgLoc): CostTrackingLocation => {
  const { id, label } = location;
  const locationLabel: NetworksOrgLoc['label'] = isObject(label) ? label : { long: label, short: label };

  return {
    id: id?.toString() || '',
    label: locationLabel
  };
};
