import { Component, ErrorInfo, PropsWithChildren } from 'react';

import { ErrorsTrackingAction, ErrorsTrackingCategory } from '@zen/types';

import NoResults from '../NoResults';
import { trackEvent } from '../TrackingProvider';
import { ReactComponent as PlaneImage } from './plane.svg';

class ErrorBoundary extends Component<PropsWithChildren<{}>, { hasError: boolean }> {
  constructor(props: {}) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    trackEvent({
      category: ErrorsTrackingCategory,
      action: ErrorsTrackingAction.JAVASCRIPT_ERROR,
      label: 'ErrorBoundary',
      properties: {
        error: error.message,
        errorInfo
      }
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="h-full" data-testid="error-page">
          <NoResults
            button={{ onClick: () => window.location.reload(), label: 'Try again' }}
            headline="We can't load the page"
            image={<PlaneImage className="w-28" />}
            tagline={<div className="w-96">Something went wrong when loading the page. We're sorry about that.</div>}
          />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
