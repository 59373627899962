import pDebounce from 'p-debounce';
import type { FC, ReactNode } from 'react';

import { highlightQuery } from '@zen/Components/Form/utils';
import { AsyncSelect, AsyncSelectProps } from '@zen/DesignSystem';
import { AccountType } from '@zen/graphql/types.generated';
import { AssignmentTypeValue, NetworksOrgLoc } from '@zen/Networks/types';
import { SEARCH_DEBOUNCE_DELAY } from '@zen/utils/constants';
import { useDivisionQuery } from '@zen/utils/hooks/graphql';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { useAccountContactsLazyQuery } from './graphql';
import { prepareResults } from './helpers';

interface Props extends Omit<AsyncSelectProps<NetworksOrgLoc>, 'formatOptionLabel' | 'loadOptions' | 'name'> {
  accountUuid: string;
  assignmentType?: AssignmentTypeValue;
  displayHeadquartersAtTop?: boolean;
  name: string;
  onChange: (value: Nullable<NetworksOrgLoc>) => void;
}

const AccountContactsSelect: FC<Props> = (props) => {
  const {
    accountUuid,
    assignmentType,
    autoFocus,
    displayHeadquartersAtTop = false,
    hasError,
    isClearable = true,
    isLoading = false,
    isDisabled = false,
    name,
    onBlur,
    onChange,
    placeholder,
    value,
    variant = 'default'
  } = props;

  const { data: divisionData } = useDivisionQuery({ variables: { id: accountUuid } });
  const [getAccountContacts] = useAccountContactsLazyQuery();

  const isDivision: boolean =
    !!divisionData?.legacyAccount?.division || divisionData?.account?.type === AccountType.DIVISION || false;
  const shouldUseDivisionAccount: boolean = isDivision && assignmentType !== AssignmentTypeValue.ORIGIN_AGENT;
  const divisionParentAccountId: string = divisionData?.account?.parentAccountId || divisionData?.account?.parentAccountId || '';
  const accountId: string = (shouldUseDivisionAccount ? divisionParentAccountId : accountUuid) || '';

  const handleInputChange = async (searchQuery: string) => {
    const withTerminals: boolean =
      assignmentType === AssignmentTypeValue.ORIGIN || assignmentType === AssignmentTypeValue.DESTINATION;
    const variables = {
      accountId,
      displayHeadquartersAtTop,
      searchContains: searchQuery,
      withTerminals
    };

    const { data } = await getAccountContacts({ variables });

    return prepareResults(data, withTerminals) as NetworksOrgLoc[];
  };

  const formatOptionLabel = (
    organisationLocation: { label?: Nullable<{ long?: Optional<string> }> },
    inputValue: string
  ): ReactNode => highlightQuery(organisationLocation?.label?.long || '', inputValue);

  const debouncedHandleInputChange = pDebounce(handleInputChange, SEARCH_DEBOUNCE_DELAY);

  return (
    <div data-testid="account-contacts-select">
      <AsyncSelect<NetworksOrgLoc>
        autoFocus={autoFocus}
        defaultOptions={value ? [value] : undefined}
        formatOptionLabel={formatOptionLabel}
        hasError={hasError}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isLoading={isLoading}
        loadOptions={debouncedHandleInputChange}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        optionKey="id"
        placeholder={placeholder}
        renderMenuInPortal={true}
        value={value}
        variant={variant}
      />
    </div>
  );
};

export default AccountContactsSelect;

export type { Props as NetworksContactInputProps };
