import type { Option } from '@zen/DesignSystem';
import type { LocationType } from '@zen/RateCards/components/types';
import { getEmptyChargeItem } from '@zen/RateCards/RateCardForm/components/RateCardChargeFormItems';
import type { ChargeItemFormValues } from '@zen/RateCards/RateCardForm/types';
import type { RateCardCharge } from '@zen/RateCards/reducer';

import { Applicability, CostTrackingLocation, PortChargeFormInitialValues, PortChargeFormValues } from './types';

export const initialValues: PortChargeFormInitialValues = {
  fromLocationId: null,
  toLocationId: null,
  chargeItems: [getEmptyChargeItem()]
};

export const findPort = (ports: CostTrackingLocation[], portId: string) => {
  return ports.find((port: CostTrackingLocation) => port.id === portId);
};

export const preparePayload = (
  values: PortChargeFormValues,
  ports: CostTrackingLocation[],
  applicability: Applicability
): RateCardCharge[] => {
  const { fromLocationId, toLocationId } = values;

  return values.chargeItems.map((chargeItem: ChargeItemFormValues) => {
    const { chargeBasis, chargeName, chargeType, unitPrice, ...rest } = chargeItem;
    const { currency, value } = unitPrice;

    return {
      applicability: [applicability],
      chargeBasis,
      chargeType,
      currency,
      customChargeValue: value,
      customCurrency: currency,
      fromLocation: findPort(ports, fromLocationId),
      itemName: chargeName,
      toLocation: findPort(ports, toLocationId),
      unitPrice: value,
      ...rest
    };
  });
};

export const preparePortOptions = (ports: CostTrackingLocation[]): Option<string>[] => {
  return ports.map((port: CostTrackingLocation) => ({
    label: port?.label?.long ?? '',
    value: port.id || ''
  }));
};

export const getChargeItemApplicability = (locationType: LocationType): Applicability => {
  return locationType === 'origin' ? Applicability.ORIGIN : Applicability.DESTINATION;
};
