import { Role } from '@zen/Auth/types';
import {
  analyticsRoutes,
  apiConsoleRoutes,
  bookingRequestRoutes,
  dashboardRoutes,
  financeRoutes,
  intakePlanningRoutes,
  networks,
  NetworksRoutes,
  orderRoutes,
  productRoutes,
  quoteRoutes,
  rateCardRoutes,
  settingsRoutes,
  shipmentRoutes,
  utilisationRoutes,
  warehouseIntakeRoutes
} from '@zen/Routes';

import type { SidebarLink } from '../types';
import type { CustomerLinksFeatureFlags } from './types';

const networksRoutes: NetworksRoutes = networks('customer');

const getAdminMenu = (isCreateAccountFeatureEnabled: boolean): SidebarLink[] => {
  if (isCreateAccountFeatureEnabled) {
    return [];
  }

  return [
    {
      icon: 'zicon-adjust',
      label: 'Admin',
      url: '',
      children: [
        {
          label: 'Networks',
          url: networksRoutes.myOrganisation.index.getUrl()
        },
        {
          label: 'Settings',
          url: settingsRoutes.index.getUrl()
        }
      ]
    }
  ];
};

export const getConfig = (role: Role, featureFlags: CustomerLinksFeatureFlags, canAccessApiConsole: boolean): SidebarLink[] => {
  const isAgent: boolean = role === Role.AGENT_FORWARDER;
  const isManufacturer: boolean = role === Role.MANUFACTURER;
  const {
    isCreateAccountFeatureEnabled,
    isFreightSpendEnabled,
    isLegacyRateCardsEnabled,
    isRateCardsEnabled,
    isUtilisationEnabled
  } = featureFlags;
  const canAccessWarehousing: boolean = !isAgent && !isManufacturer;

  return [
    {
      icon: 'zicon-dashboard',
      label: 'Dashboard',
      url: dashboardRoutes.dashboard.getUrl(),
      isExact: true
    },
    {
      icon: 'zicon-orders',
      label: 'Orders',
      url: orderRoutes.index.getUrl()
    },
    {
      icon: 'zicon-quotes',
      label: 'Quotes',
      url: quoteRoutes.quoteIndex.getUrl(),
      counter: 'quotesCount'
    },
    ...(isLegacyRateCardsEnabled || isRateCardsEnabled
      ? [
          {
            icon: 'zicon-finance' as const,
            label: 'Rate cards',
            url: rateCardRoutes.index.getUrl()
          }
        ]
      : []),
    {
      icon: 'zicon-booking-request',
      label: 'Booking requests',
      url: bookingRequestRoutes.requestIndex.getUrl(),
      counter: 'bookingRequestsCount'
    },
    {
      icon: 'zicon-shipment',
      label: 'Shipments',
      url: shipmentRoutes.shipmentIndex.getUrl()
    },
    {
      icon: 'zicon-box',
      label: 'Products',
      url: productRoutes.index.getUrl()
    },
    ...(canAccessApiConsole
      ? [
          {
            icon: 'zicon-api' as const,
            label: 'API console',
            url: apiConsoleRoutes.index.getUrl()
          }
        ]
      : []),
    {
      icon: 'zicon-intake-planning',
      label: 'Insights',
      url: '',
      children: [
        {
          label: 'Analytics',
          url: analyticsRoutes.index.getUrl()
        },
        {
          label: 'Intake planning',
          url: intakePlanningRoutes.index.getUrl()
        },
        ...(isUtilisationEnabled
          ? [
              {
                label: 'Utilisation',
                url: utilisationRoutes.index.getUrl()
              }
            ]
          : []),
        ...(isFreightSpendEnabled
          ? [
              {
                label: 'Freight spend',
                url: financeRoutes.index.getUrl()
              }
            ]
          : []),
        ...(canAccessWarehousing
          ? [
              {
                label: 'Warehousing',
                url: warehouseIntakeRoutes.index.getUrl()
              }
            ]
          : [])
      ]
    },
    ...getAdminMenu(isCreateAccountFeatureEnabled)
  ];
};
