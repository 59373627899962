import type { FC } from 'react';

import { Form, FormButtons, FormInstance, FormSelect } from '@zen/Components';
import { ChargeGroupItemType } from '@zen/CostTracking';
import { Button } from '@zen/DesignSystem';
import RateCardChargeFormItems from '@zen/RateCards/RateCardForm/components/RateCardChargeFormItems';
import type { RateCardCharge } from '@zen/RateCards/reducer';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import type { LocationType } from '../../components/types';
import { getChargeItemApplicability, initialValues, preparePayload, preparePortOptions } from './helpers';
import { getValidationSchema } from './portCharge.validation';
import type {
  Applicability,
  ChargeLocationIdType,
  CostTrackingLocation,
  PortChargeFormInitialValues,
  PortChargeFormValues
} from './types';

interface Props {
  locationType: LocationType;
  onCancel: () => void;
  onSubmit: (values: RateCardCharge[]) => void;
  onSuccess: () => void;
  ports: CostTrackingLocation[];
}

const PortChargeForm: FC<Props> = (props) => {
  const { locationType, onSubmit, onSuccess, onCancel, ports } = props;
  const originLabel: string = `Port of ${locationType}`;
  const chargeTypeApplicability: Applicability = getChargeItemApplicability(locationType);
  const locationKey: ChargeLocationIdType = locationType === 'origin' ? 'fromLocationId' : 'toLocationId';

  const handleSubmit = (values: PortChargeFormValues): Promise<IOkOrErrorResult> => {
    const portChargePayload = preparePayload(values, ports, chargeTypeApplicability);

    onSubmit(portChargePayload);

    return Promise.resolve({ ok: {}, error: null });
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<PortChargeFormInitialValues>) => {
    return (
      <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Add charges">
        <Button onClick={onCancel} variant="ghost">
          Cancel
        </Button>
      </FormButtons>
    );
  };

  return (
    <Form
      formButtons={renderFormButtons}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      validationSchema={getValidationSchema(locationKey)}
    >
      {({ values }: FormInstance<PortChargeFormValues>) => {
        const { chargeItems } = values;

        return (
          <>
            <div className="flex">
              <FormSelect className="w-1/2 pr-6" label={originLabel} name={locationKey} options={preparePortOptions(ports)} />
            </div>
            <RateCardChargeFormItems
              chargeItems={chargeItems}
              chargeTypeApplicability={chargeTypeApplicability}
              chargeTypeSearchGroups={[ChargeGroupItemType.PORT_CHARGES, ChargeGroupItemType.CUSTOMS_CLEARANCE]}
            />
          </>
        );
      }}
    </Form>
  );
};

export type { Props as PortChargeFormProps };

export default PortChargeForm;
