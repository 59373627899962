import type {
  Applicability,
  CargoOptionEnum,
  ChargeBasis,
  ChargeBasisGroup,
  ChargeClassification,
  ChargeGroup,
  CostTrackingLocation,
  CreateRateCardInput,
  Currency,
  DefaultChargesPayload
} from '@zen/graphql/types.generated';
import type { Nullable } from '@zen/utils/typescript';

export { Applicability, CargoModeEnum, ModeOfTransport } from '@zen/graphql/types.generated';

export enum ActionType {
  ADD_CUSTOM_DESTINATION_CHARGE = 'ADD_CUSTOM_DESTINATION_CHARGE',
  ADD_CUSTOM_ORIGIN_CHARGE = 'ADD_CUSTOM_ORIGIN_CHARGE',
  ADD_DESTINATION_CHARGES = 'ADD_DESTINATION_CHARGES',
  ADD_DESTINATION_HAULAGE_CHARGE = 'ADD_DESTINATION_HAULAGE_CHARGE',
  ADD_FREIGHT_CHARGE = 'ADD_FREIGHT_CHARGE',
  ADD_ORIGIN_CHARGES = 'ADD_ORIGIN_CHARGES',
  ADD_ORIGIN_HAULAGE_CHARGE = 'ADD_ORIGIN_HAULAGE_CHARGE',
  ADD_OTHER_CHARGE = 'ADD_OTHER_CHARGE',
  DELETE_CUSTOM_DESTINATION_CHARGE = 'DELETE_CUSTOM_DESTINATION_CHARGE',
  DELETE_CUSTOM_ORIGIN_CHARGE = 'DELETE_CUSTOM_ORIGIN_CHARGE',
  DELETE_DESTINATION_HAULAGE_CHARGE = 'DELETE_DESTINATION_HAULAGE_CHARGE',
  DELETE_FREIGHT_CHARGE = 'DELETE_FREIGHT_CHARGE',
  DELETE_ORIGIN_HAULAGE_CHARGE = 'DELETE_ORIGIN_HAULAGE_CHARGE',
  DELETE_OTHER_CHARGE = 'DELETE_OTHER_CHARGE',
  DISABLE_DESTINATION_CHARGE = 'DISABLE_DESTINATION_CHARGE',
  DISABLE_ORIGIN_CHARGE = 'DISABLE_ORIGIN_CHARGE',
  ENABLE_DESTINATION_CHARGE = 'ENABLE_DESTINATION_CHARGE',
  ENABLE_ORIGIN_CHARGE = 'ENABLE_ORIGIN_CHARGE',
  REINITIALIZE = 'REINITIALIZE',
  UPDATE_DESTINATION_CHARGE = 'UPDATE_DESTINATION_CHARGE',
  UPDATE_DESTINATION_HAULAGE_CHARGE = 'UPDATE_DESTINATION_HAULAGE_CHARGE',
  UPDATE_FREIGHT_CHARGE = 'UPDATE_FREIGHT_CHARGE',
  UPDATE_ORIGIN_CHARGE = 'UPDATE_ORIGIN_CHARGE',
  UPDATE_ORIGIN_HAULAGE_CHARGE = 'UPDATE_ORIGIN_HAULAGE_CHARGE',
  UPDATE_OTHER_CHARGE = 'UPDATE_OTHER_CHARGE',
  UPDATE_RATE_CARD_INPUT = 'UPDATE_RATE_CARD_INPUT'
}

export interface FreightPorts {
  destinationPorts: CostTrackingLocation[];
  originPorts: CostTrackingLocation[];
}

interface ChargeType {
  applicability?: Applicability[];
  basisGroup: ChargeBasisGroup;
  description?: string;
  group?: Pick<ChargeGroup, 'id' | 'name'>;
  id: string;
  name: string;
}

type DefaultCharge = Omit<DefaultChargesPayload, 'chargeType'> & {
  chargeType: ChargeType;
};

export interface RateCardCharge {
  applicability: Applicability[];
  cargoOptions: CargoOptionEnum[];
  chargeBasis: ChargeBasis[];
  chargeClassification?: ChargeClassification;
  chargeType: ChargeType;
  chargeTypeId?: string;
  currency: Currency;
  customChargeValue?: number;
  customCurrency?: Currency;
  defaultCharge?: Nullable<DefaultCharge>;
  defaultChargeHidden?: Nullable<boolean>;
  fromLocation?: Nullable<CostTrackingLocation>;
  itemName: string;
  toLocation?: Nullable<CostTrackingLocation>;
  unitPrice: number;
}

export interface RateCardChargeItemTypes {
  destinationCharges: RateCardCharge[];
  destinationHaulageCharges: RateCardCharge[];
  freightCharges: RateCardCharge[];
  originCharges: RateCardCharge[];
  originHaulageCharges: RateCardCharge[];
  otherCharges: RateCardCharge[];
}

export type RateCardChargeItemTypesKeys = keyof RateCardChargeItemTypes;

export type RateCardReducerState = Omit<CreateRateCardInput, 'charges' | 'createdBy'> &
  RateCardChargeItemTypes & {
    createdBy?: string;
    issuedAt?: string;
    name?: string;
    rateCardId?: string;
  };

export type RateCardInputUpdatePayload = Partial<Pick<RateCardReducerState, 'endDate' | 'issuedBy' | 'note' | 'startDate'>>;

export type AddCustomDestinationCharge = {
  payload: RateCardCharge[];
  type: ActionType.ADD_CUSTOM_DESTINATION_CHARGE;
};

export type AddCustomOriginCharge = {
  payload: RateCardCharge[];
  type: ActionType.ADD_CUSTOM_ORIGIN_CHARGE;
};

export type AddDestinationCharges = {
  payload: RateCardCharge[];
  type: ActionType.ADD_DESTINATION_CHARGES;
};

export type AddDestinationHaulageCharge = {
  payload: RateCardCharge[];
  type: ActionType.ADD_DESTINATION_HAULAGE_CHARGE;
};

export type AddFreightCharge = {
  payload: RateCardCharge[];
  type: ActionType.ADD_FREIGHT_CHARGE;
};

export type AddOriginCharges = {
  payload: RateCardCharge[];
  type: ActionType.ADD_ORIGIN_CHARGES;
};

export type AddOriginHaulageCharge = {
  payload: RateCardCharge[];
  type: ActionType.ADD_ORIGIN_HAULAGE_CHARGE;
};

export type DeleteCustomDestinationCharge = {
  payload: { atIndex: number };
  type: ActionType.DELETE_CUSTOM_DESTINATION_CHARGE;
};
export type AddOtherCharge = {
  payload: RateCardCharge[];
  type: ActionType.ADD_OTHER_CHARGE;
};

export type DeleteCustomOriginCharge = {
  payload: { atIndex: number };
  type: ActionType.DELETE_CUSTOM_ORIGIN_CHARGE;
};

export type DeleteDestinationHaulageCharge = {
  payload: { atIndex: number };
  type: ActionType.DELETE_DESTINATION_HAULAGE_CHARGE;
};

export type DeleteFreightCharge = {
  payload: { atIndex: number };
  type: ActionType.DELETE_FREIGHT_CHARGE;
};

export type DeleteOriginHaulageCharge = {
  payload: { atIndex: number };
  type: ActionType.DELETE_ORIGIN_HAULAGE_CHARGE;
};

export type DeleteOtherCharge = {
  payload: { atIndex: number };
  type: ActionType.DELETE_OTHER_CHARGE;
};

export type DisableDestinationCharge = {
  payload: { id: string };
  type: ActionType.DISABLE_DESTINATION_CHARGE;
};

export type DisableOriginCharge = {
  payload: { id: string };
  type: ActionType.DISABLE_ORIGIN_CHARGE;
};

export type EnableDestinationCharge = {
  payload: { id: string };
  type: ActionType.ENABLE_DESTINATION_CHARGE;
};

export type EnableOriginCharge = {
  payload: { id: string };
  type: ActionType.ENABLE_ORIGIN_CHARGE;
};

export type Reinitialize = {
  payload: RateCardReducerState;
  type: ActionType.REINITIALIZE;
};

export type UpdateDestinationCharge = {
  payload: { atIndex: number; value: Partial<RateCardCharge> };
  type: ActionType.UPDATE_DESTINATION_CHARGE;
};

export type UpdateDestinationHaulageCharge = {
  payload: { atIndex: number; value: Partial<RateCardCharge> };
  type: ActionType.UPDATE_DESTINATION_HAULAGE_CHARGE;
};

export type UpdateFreightCharge = {
  payload: { atIndex: number; value: Partial<RateCardCharge> };
  type: ActionType.UPDATE_FREIGHT_CHARGE;
};

export type UpdateOriginCharge = {
  payload: { atIndex: number; value: Partial<RateCardCharge> };
  type: ActionType.UPDATE_ORIGIN_CHARGE;
};

export type UpdateOriginHaulageCharge = {
  payload: { atIndex: number; value: Partial<RateCardCharge> };
  type: ActionType.UPDATE_ORIGIN_HAULAGE_CHARGE;
};

export type UpdateOtherCharge = {
  payload: { atIndex: number; value: Partial<RateCardCharge> };
  type: ActionType.UPDATE_OTHER_CHARGE;
};

export type UpdateRateCardInput = {
  payload: RateCardInputUpdatePayload;
  type: ActionType.UPDATE_RATE_CARD_INPUT;
};

export type Action =
  | AddCustomDestinationCharge
  | AddCustomOriginCharge
  | AddDestinationCharges
  | AddDestinationHaulageCharge
  | AddFreightCharge
  | AddOriginCharges
  | AddOriginHaulageCharge
  | AddOtherCharge
  | DeleteCustomDestinationCharge
  | DeleteCustomOriginCharge
  | DeleteDestinationHaulageCharge
  | DeleteFreightCharge
  | DeleteOriginHaulageCharge
  | DeleteOtherCharge
  | DisableDestinationCharge
  | DisableOriginCharge
  | EnableDestinationCharge
  | EnableOriginCharge
  | Reinitialize
  | UpdateDestinationCharge
  | UpdateDestinationHaulageCharge
  | UpdateFreightCharge
  | UpdateOriginCharge
  | UpdateOriginHaulageCharge
  | UpdateOtherCharge
  | UpdateRateCardInput;

export type { CostTrackingLocation };
