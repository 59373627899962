import type { FC } from 'react';

import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import { rateCardRoutes } from '@zen/Routes';
import useAccount from '@zen/utils/hooks/useAccount';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';
import { performMutation } from '@zen/utils/performMutation';

import type { UpdateRateCardInput } from '../../graphql/types.generated';
import { useRateCardDetails } from '../RateCardDetails/hooks';
import type { RateCard } from '../RateCardDetails/types';
import RateCardForm from '../RateCardForm';
import { getInitialValues, prepareRateCardInput } from '../RateCardForm/helpers';
import RateCardTitle from '../RateCardTitle';
import type { RateCardReducerState } from '../reducer';
import { useUpdateRateCardMutation } from './graphql';

interface Props {}

export const EditRateCard: FC<Props> = () => {
  const { accountUuid: accountId, userProfile } = useAccount();
  const { uuid: currentUserId } = userProfile;
  const { data, loading, error } = useRateCardDetails();
  const { addError, addSuccess } = useNotification();
  const { navigateBack } = useNavigationHistory();
  const [updateRateCard, { loading: isUpdating }] = useUpdateRateCardMutation();

  const defaultBackUrl: string = rateCardRoutes.index.getUrl();

  const handleSuccess = (): void => {
    addSuccess('Rate card has been successfully updated.');
    navigateBack(defaultBackUrl);
  };

  const handleSubmit = async (values: RateCardReducerState): Promise<void> => {
    const input: UpdateRateCardInput = {
      ...prepareRateCardInput(values),
      rateCardId: values.rateCardId || '',
      updatedBy: currentUserId
    };

    await performMutation({
      mutationFn: () =>
        updateRateCard({
          variables: {
            input
          }
        }),
      onError: () => addError(),
      onSuccess: handleSuccess
    });
  };

  return (
    <QueryHandler data={data?.getRateCard?.rateCard} error={!!error} isLoading={loading}>
      {(rateCard: RateCard) => {
        const { modeOfTransport, name } = rateCard;

        return (
          <Page defaultBackUrl={defaultBackUrl} title={<RateCardTitle modeOfTransport={modeOfTransport} rateCardName={name} />}>
            <RateCardForm
              initialValues={getInitialValues(accountId, currentUserId, rateCard)}
              isLoading={isUpdating}
              onSubmit={handleSubmit}
            />
          </Page>
        );
      }}
    </QueryHandler>
  );
};

export default EditRateCard;
