import type { FC } from 'react';

import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import RateCardNotes from '@zen/RateCards/RateCardNotes';
import TermsAndConditionsLink from '@zen/RateCards/TermsAndConditionsLink';
import { rateCardRoutes } from '@zen/Routes';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

import FreightChargeTable from '../FreightCharges/FreightChargeTable';
import HaulageChargeTable from '../HaulageCharges/HaulageChargeTable';
import OtherChargeTable from '../OtherCharges/OtherChargeTable';
import PortChargeTable from '../PortCharges/PortChargeTable';
import RateCardActions from '../RateCardActions';
import { RateCardProvider } from '../RateCardContext';
import RateCardTitle from '../RateCardTitle';
import type { RateCardCharge } from '../reducer';
import { getChargesClassification } from './helpers';
import { useRateCardDetails } from './hooks';
import RateCardActionsButtons from './RateCardActionsButtons';
import RateCardOverviewDetails from './RateCardOverviewDetails';
import type { FormattedRateCardDetails, RateCard } from './types';

const RateCardDetails: FC = () => {
  const { data, loading, error } = useRateCardDetails();
  const { navigateBack } = useNavigationHistory();

  const handleDeleteCompleted = (): void => {
    navigateBack(rateCardRoutes.index.getUrl());
  };

  return (
    <QueryHandler data={data?.getRateCard?.rateCard} error={!!error} isLoading={loading}>
      {(rateCard: RateCard) => {
        const rateCardDetails: FormattedRateCardDetails = getChargesClassification(rateCard);

        const {
          cargoType,
          destinationCharges,
          destinationHaulageCharges,
          freightCharges,
          modeOfTransport,
          name,
          note,
          originCharges,
          originHaulageCharges,
          otherCharges,
          rateCardId
        } = rateCardDetails;

        const preparePortCharges = (portCharges: RateCardCharge[]): RateCardCharge[] =>
          portCharges?.filter((portCharge) => !portCharge.defaultChargeHidden) || [];

        const originPortCharges: RateCardCharge[] = preparePortCharges(originCharges);
        const destinationPortCharges: RateCardCharge[] = preparePortCharges(destinationCharges);

        return (
          <RateCardProvider cargoType={cargoType} isEditable={false} modeOfTransport={modeOfTransport}>
            <RateCardActions onDeleteCompleted={handleDeleteCompleted}>
              {({ handleClone, handleDelete, handleEdit }) => {
                return (
                  <Page
                    actionButtons={
                      <RateCardActionsButtons
                        onClone={() => handleClone(rateCardId)}
                        onDelete={() => handleDelete(rateCardId)}
                        onEdit={() => handleEdit(rateCardId)}
                      />
                    }
                    defaultBackUrl={rateCardRoutes.index.getUrl()}
                    title={<RateCardTitle modeOfTransport={modeOfTransport} rateCardName={name} />}
                  >
                    <div className="flex flex-col space-y-6">
                      <RateCardOverviewDetails rateCard={rateCardDetails} />
                      <FreightChargeTable charges={freightCharges} />
                      <HaulageChargeTable charges={originHaulageCharges} type="origin" />
                      <PortChargeTable charges={originPortCharges} type="origin" />
                      <PortChargeTable charges={destinationPortCharges} type="destination" />
                      <HaulageChargeTable charges={destinationHaulageCharges} type="destination" />
                      <OtherChargeTable charges={otherCharges} />
                      <RateCardNotes editable={false} value={note || ''} />
                      <TermsAndConditionsLink />
                    </div>
                  </Page>
                );
              }}
            </RateCardActions>
          </RateCardProvider>
        );
      }}
    </QueryHandler>
  );
};

export default RateCardDetails;
