import type { FC, ReactNode } from 'react';

import Page from '@zen/Components/Page';
import { Button, Table } from '@zen/DesignSystem';
import { rateCardRoutes } from '@zen/Routes';
import useAccount from '@zen/utils/hooks/useAccount';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

import useRateCards from './graphql/hooks/useRateCards';
import RateCardActions from './RateCardActions';
import { getRateCardsTableConfiguration } from './rateCardsTableConfiguration';

const RateCards: FC = () => {
  const { navigate } = useNavigationHistory();
  const { accountUuid } = useAccount();
  const { nodes, loading, paginationInfo, refetch, totalCount } = useRateCards({
    customerId: accountUuid
  });

  const handleNewRateCardClick = (): void => {
    navigate(rateCardRoutes.new.getUrl());
  };

  const newRateCardButton: ReactNode = (
    <Button onClick={handleNewRateCardClick} variant="secondary">
      New rate card
    </Button>
  );

  return (
    <RateCardActions onDeleteCompleted={refetch}>
      {({ handleClone, handleDelete, handleEdit }) => {
        return (
          <Page actionButtons={newRateCardButton} title="Rate cards">
            <Table
              columns={getRateCardsTableConfiguration({ onClone: handleClone, onDelete: handleDelete, onEdit: handleEdit })}
              data={nodes || []}
              loading={loading}
              paginationInfo={paginationInfo}
              tableId="rateCardsTable"
              totalCountConfig={{ entityName: 'rate card', totalCount }}
            />
          </Page>
        );
      }}
    </RateCardActions>
  );
};

export default RateCards;
