import type {
  Account,
  AccountMember,
  ActivityUserDetails,
  BookingCustomerType,
  BookingRequest,
  CargoMetadataContainerType,
  CargoMetadataLooseCargoType,
  CargoMetadataVehicleType,
  NetworksContact,
  PurchaseOrder,
  Role,
  StageValue
} from '@zen/graphql/types.generated';
import type { OrgLocType } from '@zen/Networks/types';
import type { DeepNonNullable, Nullable, Optional } from '@zen/utils/typescript';

import type { GetActivityFeedQueryResult } from './graphql';

export type {
  ActionItem,
  BookingCollectionMetadataType,
  BookingDeliveryMetadataType,
  IssueActivityMetadataType,
  Metadata,
  Permissions,
  PurchaseOrderCargoReadyDatesMetadataType,
  PurchaseOrderClosedMetadataType,
  PurchaseOrderDeliveryEstimatesMetadataType,
  PurchaseOrderLotsDateType,
  ShipmentEtaMetadataType,
  TextMessage
} from '@zen/graphql/types.generated';
export {
  ActionItemActionType,
  ActionItemStatus,
  ActivityFeedItemTypeEnum,
  ActivityFeedTargetTypeEnum,
  Currency,
  QuoteOptionState,
  RejectedReasonEnum,
  Role,
  StageValue
} from '@zen/graphql/types.generated';

export type CargoMetadata = CargoMetadataContainerType & CargoMetadataLooseCargoType & CargoMetadataVehicleType;

interface ActivityFeedOrganisation {
  organisation: {
    name: string;
  };
}

export type BookingRequestType = Pick<BookingRequest, 'rejectedReason' | 'rejectedAdditionalInfo' | 'id'>;

export interface ActivityFeedDataItem extends Activity {
  data: Optional<object>;
}

// these types could be removed just need to agree on how we will define types
type ActivityFeedContact = Pick<NetworksContact, 'firstName' | 'lastName' | 'email' | 'avatarUrl' | 'assignedRoles'>;

export type LegacyActivityFeedUser = ActivityFeedContact & ActivityFeedOrganisation;

export type ActivityFeedUser = Pick<AccountMember, 'firstName' | 'lastName' | 'email'> & {
  account: Pick<Account, 'tradingName'>;
};

export type ActivityUser = ActivityUserDetails;

type ActivityType = DeepNonNullable<GetActivityFeedQueryResult, 'nodes'>['activityFeed']['activities']['nodes'];

export interface Activity extends Omit<ActivityType, 'legacyUser'> {
  // extend type for legacy text message
  legacyContent?: string;
  legacyUser: LegacyActivityFeedUser;
  user: Nullable<ActivityFeedUser>;
}

export type CollectionAndDeliveryStatus = 'requested' | 'rescheduled' | 'confirmed' | 'completed';

export type DeliveryItemType = Pick<PurchaseOrder, 'id' | 'lotsRequiredDeliveryDate'>;

export type CargoReadyDateItemType = Pick<PurchaseOrder, 'id' | 'lotsInitialCargoReadyDate'>;

export interface PermissionParties {
  customer: BookingCustomerType;
  manufacturers: [OrgLocType];
  originAgent: OrgLocType;
}

export interface PermittedParty {
  organisationName: string;
  role: Role;
}

export interface PermittedParties {
  agentForwarder?: PermittedParty;
  customerUser?: PermittedParty;
  forwarder?: PermittedParty;
  manufacturer?: Array<PermittedParty>;
}

// Those values are used into viewType property to display activity feed input parties initial configuration and permissions used to call the mutation
export type ActivityFeedOriginViewType = StageValue.BOOKING_REQUESTED | StageValue.QUOTE_REQUESTED;

export const ActivityFeedTrackingCategory = 'ActivityFeed';

export enum ActivityFeedTrackingAction {
  SUBSCRIPTIONS_TOGGLER_CLICKED = 'SubscriptionsTogglerClicked'
}
