import { sumBy } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { VictoryContainer, VictoryLabel, VictoryPie } from 'victory';
import { VictorySharedEvents, VictorySharedEventsProps } from 'victory-shared-events';

import { Color } from '@zen/Styleguide';

import { getChartData, getInnerLabelStyles, getPieChartMetrics, svgLabelStyle } from './helpers';
import type { PieChartDataPoint, PieChartMetrics, SliceDatum, VictoryExternalChartMutation } from './types';

// color is assigned by index, if more slices provides it will start from 0 again
const defaultSliceColors: Color[] = [Color.RED_BASE, Color.ORANGE_BASE, Color.AZURE_LIGHT, Color.GREEN_BASE, Color.GREY_BASE];

export interface Props {
  className?: string;
  innerLabels: string[];
  selectedValueKey?: string;
  showPercentage?: boolean;
  // maximum 3 rows...
  values: PieChartDataPoint[];
}

export const metrics: PieChartMetrics = getPieChartMetrics({
  innerRadius: 40,
  ringWidth: 24,
  expandedRingWidth: 40
});

const PieChart: FC<Props> = ({ className, innerLabels, values, selectedValueKey, showPercentage }) => {
  const [externalChartMutations, setExternalChartMutations] =
    useState<VictorySharedEventsProps['externalEventMutations']>(undefined);

  const hasVisibleSlices: boolean = sumBy(values, (point: PieChartDataPoint) => point.value) > 0;
  const chartData: SliceDatum[] = getChartData(values, defaultSliceColors);
  const innerLabelVerticalOffset: number = innerLabels.length > 1 ? 6 : 0;

  const pieHoverChartMutation = useCallback((): unknown => {
    return {
      radius: metrics.expandedEndRadius
    };
  }, []);

  const labelHoverChartMutation = useCallback(
    ({ datum }: { datum: SliceDatum }) => {
      return {
        text: showPercentage ? `${datum.percentage}%` : datum.y,
        radius: metrics.expandedEndRadius,
        backgroundStyle: {
          fill: datum.fill,
          pointerEvents: 'none'
        },
        backgroundPadding: datum.percentage <= 5 ? 2 : 0
      };
    },
    [showPercentage]
  );

  const resetChartMutation = useCallback((): unknown => {
    return null;
  }, []);

  const expandSliceHandler = (): VictoryExternalChartMutation[] => {
    return [
      {
        target: 'data',
        mutation: pieHoverChartMutation,
        callback: () => {
          setExternalChartMutations(undefined);
        }
      },
      {
        target: 'labels',
        mutation: labelHoverChartMutation,
        callback: () => {
          setExternalChartMutations(undefined);
        }
      }
    ];
  };

  const resetSliceHandler = (): VictoryExternalChartMutation[] => {
    return [
      {
        target: 'data',
        mutation: resetChartMutation,
        callback: () => {
          setExternalChartMutations(undefined);
        }
      },
      {
        target: 'labels',
        mutation: resetChartMutation,
        callback: () => {
          setExternalChartMutations(undefined);
        }
      }
    ];
  };

  const createEnterSliceMutations = useCallback(
    (sliceDataKey: string): VictoryExternalChartMutation[] => [
      {
        childName: ['pie'],
        target: ['data'],
        eventKey: sliceDataKey,
        mutation: pieHoverChartMutation,
        callback: () => {
          setExternalChartMutations(undefined);
        }
      },
      {
        childName: ['pie'],
        target: ['labels'],
        eventKey: sliceDataKey,
        mutation: labelHoverChartMutation,
        callback: () => {
          setExternalChartMutations(undefined);
        }
      }
    ],
    [pieHoverChartMutation, labelHoverChartMutation]
  );

  const leaveSliceMutations = useMemo(
    (): VictoryExternalChartMutation[] => [
      {
        childName: ['pie'],
        target: ['data'],
        eventKey: 'all',
        mutation: () => {
          return { radius: metrics.endRadius };
        },
        callback: () => {
          setExternalChartMutations(undefined);
        }
      },
      {
        childName: ['pie'],
        target: ['labels'],
        eventKey: 'all',
        mutation: () => {
          return {
            text: '',
            radius: metrics.endRadius,
            backgroundStyle: null,
            backgroundPadding: 0
          };
        },
        callback: () => {
          setExternalChartMutations(undefined);
        }
      }
    ],
    []
  );

  useEffect(() => {
    if (selectedValueKey) {
      setExternalChartMutations(createEnterSliceMutations(selectedValueKey));
    } else {
      setExternalChartMutations(leaveSliceMutations);
    }
  }, [createEnterSliceMutations, leaveSliceMutations, selectedValueKey]);

  return (
    <div className={className} data-testid="pie-chart">
      <VictoryContainer height={metrics.height} width={metrics.width}>
        <>
          {hasVisibleSlices && (
            <VictorySharedEvents
              events={[
                {
                  childName: ['pie'],
                  target: 'data',
                  eventHandlers: {
                    onMouseEnter: expandSliceHandler,
                    onMouseLeave: resetSliceHandler
                  }
                }
              ]}
              externalEventMutations={externalChartMutations}
            >
              <VictoryPie
                colorScale={defaultSliceColors}
                data={chartData}
                height={metrics.height}
                innerRadius={metrics.startRadius}
                labelComponent={<VictoryLabel className="pointer-events-none" />}
                labelRadius={metrics.labelRadius}
                labels={() => ''}
                name="pie"
                radius={metrics.endRadius}
                standalone={false}
                style={{
                  data: {
                    fill: ({ datum }) => datum.fill
                  },
                  labels: svgLabelStyle({ fontSize: '12', fill: Color.WHITE, fontWeight: 'bold' })
                }}
                width={metrics.width}
              />
            </VictorySharedEvents>
          )}
          {!hasVisibleSlices && (
            <VictoryPie
              colorScale={[Color.GREY_LIGHT]}
              data={[
                {
                  x: 0,
                  y: 100
                }
              ]}
              height={metrics.height}
              innerRadius={metrics.startRadius}
              labelRadius={metrics.labelRadius}
              labels={() => ''}
              name="pie-empty"
              radius={metrics.endRadius}
              standalone={false}
              width={metrics.width}
            />
          )}
          <VictoryLabel
            dy={innerLabelVerticalOffset}
            style={getInnerLabelStyles()}
            text={innerLabels}
            textAnchor="middle"
            verticalAnchor="middle"
            x={metrics.width / 2}
            y={metrics.height / 2}
          />
        </>
      </VictoryContainer>
    </div>
  );
};

export default PieChart;
