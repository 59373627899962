import type { FC, ReactNode } from 'react';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import type { Document } from '@zen/Components/Documents/types';
import { Form, FormArray, FormArrayHelpers, FormButtons, FormInput, FormInstance, FormSelect } from '@zen/Components/Form';
import FormRolePermissionToggles from '@zen/Components/Form/FormRolePermissionToggles';
import type { Option } from '@zen/DesignSystem';
import { Button } from '@zen/DesignSystem';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import { validationSchema } from './documentListForm.validation';

export interface DocumentListValues {
  documents: Document[];
}

interface Props {
  documentTypes: Option<string>[];
  initialValues: DocumentListValues;
  onCancel: () => void;
  onSubmit: (values: DocumentListValues) => void;
}

const DocumentListForm: FC<Props> = (props) => {
  const { documentTypes, initialValues, onCancel, onSubmit } = props;
  const { check } = useGlobalPermissions();
  const canEditPermissions = check('bookings.canViewDocumentPermissions');
  const permissionsLabel = (
    <span>
      Access rights - <span className="text-xs font-normal">Select who can see and manage this document</span>
    </span>
  );

  const handleSubmit = ({ documents }: { documents: Document[] }): Promise<IOkOrErrorResult> => {
    const payload = {
      documents: documents.map(({ progress, ...document }) => document)
    };

    return Promise.resolve({
      ok: { data: payload },
      error: null
    });
  };

  const handleSuccess = (payload: { documents: Document[] }): void => {
    onSubmit(payload);
  };

  const renderDocumentForm = (document: Document, getFieldName: (name: keyof Document) => string) => {
    const isTypeDisabled = !!initialValues.documents[0].documentType;
    const type = document.documentType;

    const shouldDisplayDocumentSelect = (): boolean => {
      const hasMatchingDocumentType: boolean = !!documentTypes.find(
        (documentItem: Option<string>) => documentItem.value === type
      );

      return !type || hasMatchingDocumentType;
    };

    const renderDocumentSelectionComponent = (): ReactNode => {
      if (shouldDisplayDocumentSelect()) {
        return (
          <FormSelect
            className="flex-1"
            isDisabled={isTypeDisabled}
            label="Document type"
            name={getFieldName('documentType')}
            options={documentTypes}
          />
        );
      }

      return <FormInput className="flex-1 mr-4" disabled={true} label="Document type" name={getFieldName('documentType')} />;
    };

    return (
      <div>
        <div className="flex justify-between">
          <FormInput className="flex-1 mr-4" label="Document name" name={getFieldName('description')} />
          {renderDocumentSelectionComponent()}
        </div>
        {canEditPermissions && <FormRolePermissionToggles label={permissionsLabel} name={getFieldName('permissions')} />}
      </div>
    );
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<DocumentListValues>): ReactNode => (
    <div className="mt-32">
      <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Upload">
        <Button data-testid="cancel-btn" onClick={() => onCancel()} variant="ghost">
          Cancel
        </Button>
      </FormButtons>
    </div>
  );

  return (
    <Form
      enableReinitialize={true}
      formButtons={renderFormButtons}
      formName="DocumentListForm"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      validationSchema={validationSchema}
    >
      {(form: FormInstance<DocumentListValues>) => (
        <FormArray hideAddButton={true} path="documents" values={form.values.documents}>
          {({ value, getFieldName }: FormArrayHelpers<Document>) => renderDocumentForm(value, getFieldName)}
        </FormArray>
      )}
    </Form>
  );
};

export type { Props as DocumentListFormProps };

export default DocumentListForm;
