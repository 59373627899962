import { format, parseISO } from 'date-fns';
import type { ReactNode } from 'react';
import {
  dateMatchModifiers,
  DateRange as DayPickerDateRange,
  DayPickerProps,
  Matcher as DayPickerMatcher,
  PropsBase,
  PropsRange,
  PropsSingle
} from 'react-day-picker';

import type { Optional } from '@zen/utils/typescript';

import type { DatePickerProps, DateRangePickerProps, Matcher, SingleDatePickerProps, TooltipConfig } from './types';

export const convertDateStringIntoDateObject = (dateString: string): Date => {
  return parseISO(dateString);
};

export const convertDateObjectIntoDateString = (date: Optional<Date>): string => {
  return date ? format(date, 'yyyy-MM-dd') : '';
};

export const prepareMatchers = (input: Matcher | undefined): DayPickerMatcher[] | undefined => {
  if (!input) return undefined;

  const matchers = [];

  if (input.minDate) {
    matchers.push({ before: convertDateStringIntoDateObject(input.minDate) });
  }

  if (input.maxDate) {
    matchers.push({ after: convertDateStringIntoDateObject(input.maxDate) });
  }

  if (input.dateRanges) {
    const dateRanges = input.dateRanges?.map(({ startDate, endDate }) => ({
      from: convertDateStringIntoDateObject(startDate),
      to: convertDateStringIntoDateObject(endDate)
    }));

    matchers.push(...dateRanges);
  }

  if (input.dayOfWeek) {
    matchers.push({ dayOfWeek: input.dayOfWeek });
  }

  return matchers;
};

export const formatWeekdayName = (date: Date): string => {
  // returns day of the week, example: M = Monday, T = Tuesday, etc..
  return format(date, 'EEEEE');
};

export const getTooltipContent = (date: Date, tooltip: TooltipConfig | TooltipConfig[] | undefined): ReactNode | undefined => {
  if (!tooltip) return;

  if (Array.isArray(tooltip)) {
    const matchedTooltip = tooltip.find(({ matcher }) => isDateMatched(date, matcher));

    return matchedTooltip ? matchedTooltip.message : undefined;
  }

  return isDateMatched(date, tooltip.matcher) ? tooltip.message : undefined;
};

const isDateMatched = (date: Date, matcher: Matcher): boolean => {
  const matchers = prepareMatchers(matcher);

  return matchers ? dateMatchModifiers(date, matchers) : false;
};

export const isDateRangePicker = (props: DatePickerProps): props is DateRangePickerProps => {
  return props.mode === 'range';
};

export const isSingleDatePicker = (props: DatePickerProps): props is SingleDatePickerProps => {
  return props.mode === 'single';
};

export const prepareCustomProps = (props: DatePickerProps): DayPickerProps => {
  if (isSingleDatePicker(props)) {
    return prepareSingleDatePickerProps(props);
  }

  if (isDateRangePicker(props)) {
    return prepareDateRangePickerProps(props);
  }

  return {};
};

export const prepareSingleDatePickerProps = ({ selected, onChange }: SingleDatePickerProps): PropsBase & PropsSingle => {
  const selectedDate: Date | undefined = selected ? convertDateStringIntoDateObject(selected) : undefined;

  const handleSelect = (date: Date | undefined): void => {
    if (!date) {
      return onChange('');
    }

    onChange(convertDateObjectIntoDateString(date));
  };

  return {
    defaultMonth: selectedDate,
    mode: 'single',
    onSelect: handleSelect,
    selected: selectedDate
  };
};

const prepareDateRangePickerProps = ({ selected, onChange }: DateRangePickerProps): PropsBase & PropsRange => {
  const selectedDateRange: DayPickerDateRange | undefined = selected
    ? {
        from: convertDateStringIntoDateObject(selected.from),
        to: selected.to ? convertDateStringIntoDateObject(selected.to) : undefined
      }
    : undefined;

  const handleSelect = (dateRange: DayPickerDateRange | undefined): void => {
    if (!dateRange) {
      return onChange(undefined);
    }

    onChange({
      from: convertDateObjectIntoDateString(dateRange.from),
      to: convertDateObjectIntoDateString(dateRange.to)
    });
  };

  return {
    defaultMonth: selectedDateRange?.from,
    mode: 'range',
    onSelect: handleSelect,
    selected: selectedDateRange
  };
};
