/* eslint-disable no-nested-ternary */
import type { ActivityFeedUser, ActivityUser, LegacyActivityFeedUser } from '@zen/ActivityFeed';
import type { Nullable } from '@zen/utils/typescript';

export const getActivityFeedUserInformation = (
  legacyUser?: Nullable<LegacyActivityFeedUser>,
  user?: Nullable<ActivityFeedUser>,
  userDetails?: Nullable<ActivityUser>
) => {
  return {
    firstName: user ? user.firstName : legacyUser ? legacyUser.firstName : userDetails ? userDetails.firstName : '',
    email: user ? user.email : legacyUser ? legacyUser.email : userDetails ? userDetails.email : '',
    lastName: user ? user.lastName : legacyUser ? legacyUser.lastName : userDetails ? userDetails.lastName : '',
    organisationName: user
      ? user.account.tradingName
      : legacyUser
      ? legacyUser.organisation?.name
      : userDetails
      ? userDetails.organisationName
      : ''
  };
};
