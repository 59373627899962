import { FeaturesReady, GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { FC, PropsWithChildren, useEffect } from 'react';

import { SplashScreen } from '@zen/DesignSystem';
import staticConfig from '@zen/utils/staticConfig';

const growthBookClient = new GrowthBook({
  apiHost: staticConfig.growthBookApiHost,
  clientKey: staticConfig.growthBookClientKey,
  enableDevMode: staticConfig.environment === 'development'
});

const FeatureFlagProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  useEffect(() => {
    growthBookClient.loadFeatures({ autoRefresh: true });
  }, []);

  return (
    <GrowthBookProvider growthbook={growthBookClient}>
      <FeaturesReady fallback={<SplashScreen />}>{children}</FeaturesReady>
    </GrowthBookProvider>
  );
};

export default FeatureFlagProvider;
export { growthBookClient };
