import { FC, useState } from 'react';
import { useLocalStorage } from 'react-use';

import { useCreateNewMessageMutation } from '@zen/ActivityFeed/graphql';
import type { ActivityFeedOriginViewType, ActivityFeedTargetTypeEnum, PermittedParties } from '@zen/ActivityFeed/types';
import useRole from '@zen/Auth/hooks/useRole';
import SubmittableInput from '@zen/Components/Form/SubmittableInput/SubmittableInput';
import type { PermissionsInput } from '@zen/graphql/types.generated';
import useAccount from '@zen/utils/hooks/useAccount';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import { getStoragePrefix } from '../../helpers/activityFeedHelper';
import { createPermissions } from '../../helpers/createPermissions';
import ActivityFeedInputPermissions from './ActivityFeedInputPermissions';

interface Props {
  permittedParties: PermittedParties;
  targetId: string;
  targetType: ActivityFeedTargetTypeEnum;
  viewType?: ActivityFeedOriginViewType;
}

const ActivityFeedInput: FC<Props> = ({ viewType, targetType, targetId, permittedParties }) => {
  const storagePrefix: string = getStoragePrefix(targetId);
  const role = useRole();
  const user = useAccount();
  const [value, setValue] = useLocalStorage<string>(storagePrefix, '');
  const [disabled, setDisabled] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<PermissionsInput>(createPermissions(role, viewType));

  const handlePermissionChange = (key: keyof PermissionsInput, permissionValue: boolean) => {
    setPermissions((currentPermissions: PermissionsInput) => ({
      ...currentPermissions,
      [key]: permissionValue
    }));
  };

  const { addError } = useNotification();
  const [createNewTextMessage] = useCreateNewMessageMutation();

  const handleChange = (inputValue: string): void => {
    setValue(inputValue);
  };

  const handleSuccess = () => {
    setValue('');
    setDisabled(false);
  };

  const handleError = () => addError('Something went wrong. Please try sending message again.');

  const handleSubmit = async (inputValue: string): Promise<void> => {
    const input = {
      content: inputValue,
      targetType,
      targetId,
      permissions,
      userDetails: {
        email: user.userProfile.email,
        firstName: user.userProfile.firstName,
        lastName: user.userProfile.lastName,
        organisationName: user.userProfile.registeredCompanyName
      }
    };

    setDisabled(true);

    await performMutation({
      mutationFn: () => createNewTextMessage({ variables: { input } }),
      onError: handleError,
      onSuccess: handleSuccess
    });
  };

  const wrapperClassNames: string = 'border border-solid border-grey-lighter rounded';
  const permissionsStylesProps: Record<string, string> = {
    customBorder: 'border-b border-solid border-grey-lighter',
    customButtonClasses: 'text-lg w-12'
  };

  return (
    <div className={wrapperClassNames}>
      <SubmittableInput
        isDisabled={disabled || !value}
        onChange={handleChange}
        onSubmit={handleSubmit}
        placeholder="Type your message here..."
        value={value}
        {...permissionsStylesProps}
      />
      <ActivityFeedInputPermissions
        onPermissionChange={handlePermissionChange}
        permissions={permissions}
        permittedParties={permittedParties}
        userRole={role}
      />
    </div>
  );
};

export type { Props as ActivityFeedInputProps };
export default ActivityFeedInput;
