import type { FC } from 'react';

import { Button, IconButton } from '@zen/DesignSystem';

interface Props {
  onClone: () => void;
  onDelete: () => void;
  onEdit: () => void;
}

const RateCardActionsButtons: FC<Props> = ({ onClone, onDelete, onEdit }) => {
  return (
    <div className="flex space-x-2" data-testid="rate-card-actions-button">
      <Button onClick={onEdit} variant="secondary">
        Edit rate card
      </Button>
      <Button onClick={onClone} variant="secondary">
        Clone rate card
      </Button>
      <IconButton icon="zicon-trash" onClick={onDelete} variant="secondary" />
    </div>
  );
};

export default RateCardActionsButtons;
