import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { BusinessHoursFragmentDoc } from '../../../../graphql/fragments/BusinessHours.generated';
import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type JourneyShippingMilestoneFragment = { __typename?: 'JourneyShippingMilestone' } & Pick<
  GraphQLTypes.JourneyShippingMilestone,
  | 'canManageDates'
  | 'canManageDelays'
  | 'canUpdateActualDate'
  | 'canUpdatePlannedDate'
  | 'canUpdateRequestedBy'
  | 'canUpdateRequestedDate'
  | 'category'
  | 'completed'
  | 'dateType'
  | 'delayInDays'
  | 'id'
  | 'milestoneType'
  | 'name'
  | 'requestedBy'
> & {
    actual?: GraphQLTypes.Maybe<{ __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>>;
    canManageDatesCurrently?: GraphQLTypes.Maybe<
      { __typename?: 'JourneyManageDatesValidationResult' } & Pick<
        GraphQLTypes.JourneyManageDatesValidationResult,
        'reason' | 'value'
      >
    >;
    canRemoveActualDateCurrently?: GraphQLTypes.Maybe<
      { __typename?: 'JourneyRemoveActualDateValidationResult' } & Pick<
        GraphQLTypes.JourneyRemoveActualDateValidationResult,
        'reason' | 'value'
      >
    >;
    canRemovePlannedDateCurrently?: GraphQLTypes.Maybe<
      { __typename?: 'JourneyRemovePlannedDateValidationResult' } & Pick<
        GraphQLTypes.JourneyRemovePlannedDateValidationResult,
        'reason' | 'value'
      >
    >;
    canRemoveRequestedDateCurrently?: GraphQLTypes.Maybe<
      { __typename?: 'JourneyRemoveRequestedDateValidationResult' } & Pick<
        GraphQLTypes.JourneyRemoveRequestedDateValidationResult,
        'reason' | 'value'
      >
    >;
    canUpdateActualDateCurrently?: GraphQLTypes.Maybe<
      { __typename?: 'JourneyActualDateValidationResult' } & Pick<
        GraphQLTypes.JourneyActualDateValidationResult,
        'value' | 'reason'
      >
    >;
    canUpdatePlannedDateCurrently?: GraphQLTypes.Maybe<
      { __typename?: 'JourneyPlannedDateValidationResult' } & Pick<
        GraphQLTypes.JourneyPlannedDateValidationResult,
        'value' | 'reason'
      >
    >;
    canUpdateRequestedDateCurrently?: GraphQLTypes.Maybe<
      { __typename?: 'JourneyRequestedDateValidationResult' } & Pick<
        GraphQLTypes.JourneyRequestedDateValidationResult,
        'value' | 'reason'
      >
    >;
    delays: Array<
      { __typename?: 'JourneyShippingMilestoneDelay' } & Pick<GraphQLTypes.JourneyShippingMilestoneDelay, 'id' | 'days'> & {
          reason?: GraphQLTypes.Maybe<
            { __typename?: 'ShippingDelayReason' } & Pick<GraphQLTypes.ShippingDelayReason, 'id' | 'reason'>
          >;
        }
    >;
    initialEstimate?: GraphQLTypes.Maybe<
      { __typename?: 'LocalDateTimeRange' } & {
        startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>;
        endDateTime?: GraphQLTypes.Maybe<
          { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
        >;
      }
    >;
    latestEstimate?: GraphQLTypes.Maybe<
      { __typename?: 'LocalDateTimeRange' } & {
        startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>;
        endDateTime?: GraphQLTypes.Maybe<
          { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
        >;
      }
    >;
    planned?: GraphQLTypes.Maybe<
      { __typename?: 'LocalDateTimeRange' } & {
        endDateTime?: GraphQLTypes.Maybe<
          { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
        >;
        startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>;
      }
    >;
    requested?: GraphQLTypes.Maybe<
      { __typename?: 'LocalDateTimeRange' } & {
        endDateTime?: GraphQLTypes.Maybe<
          { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
        >;
        startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>;
      }
    >;
  };

export type JourneyStopFragment = { __typename?: 'JourneyStop' } & Pick<
  GraphQLTypes.JourneyStop,
  'canUpdateLocation' | 'canUpdateReference' | 'canViewReference' | 'id' | 'locationType' | 'reference' | 'stopAction'
> & {
    shippingMilestones: Array<
      { __typename?: 'JourneyShippingMilestone' } & Pick<
        GraphQLTypes.JourneyShippingMilestone,
        | 'canManageDates'
        | 'canManageDelays'
        | 'canUpdateActualDate'
        | 'canUpdatePlannedDate'
        | 'canUpdateRequestedBy'
        | 'canUpdateRequestedDate'
        | 'category'
        | 'completed'
        | 'dateType'
        | 'delayInDays'
        | 'id'
        | 'milestoneType'
        | 'name'
        | 'requestedBy'
      > & {
          actual?: GraphQLTypes.Maybe<
            { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
          >;
          canManageDatesCurrently?: GraphQLTypes.Maybe<
            { __typename?: 'JourneyManageDatesValidationResult' } & Pick<
              GraphQLTypes.JourneyManageDatesValidationResult,
              'reason' | 'value'
            >
          >;
          canRemoveActualDateCurrently?: GraphQLTypes.Maybe<
            { __typename?: 'JourneyRemoveActualDateValidationResult' } & Pick<
              GraphQLTypes.JourneyRemoveActualDateValidationResult,
              'reason' | 'value'
            >
          >;
          canRemovePlannedDateCurrently?: GraphQLTypes.Maybe<
            { __typename?: 'JourneyRemovePlannedDateValidationResult' } & Pick<
              GraphQLTypes.JourneyRemovePlannedDateValidationResult,
              'reason' | 'value'
            >
          >;
          canRemoveRequestedDateCurrently?: GraphQLTypes.Maybe<
            { __typename?: 'JourneyRemoveRequestedDateValidationResult' } & Pick<
              GraphQLTypes.JourneyRemoveRequestedDateValidationResult,
              'reason' | 'value'
            >
          >;
          canUpdateActualDateCurrently?: GraphQLTypes.Maybe<
            { __typename?: 'JourneyActualDateValidationResult' } & Pick<
              GraphQLTypes.JourneyActualDateValidationResult,
              'value' | 'reason'
            >
          >;
          canUpdatePlannedDateCurrently?: GraphQLTypes.Maybe<
            { __typename?: 'JourneyPlannedDateValidationResult' } & Pick<
              GraphQLTypes.JourneyPlannedDateValidationResult,
              'value' | 'reason'
            >
          >;
          canUpdateRequestedDateCurrently?: GraphQLTypes.Maybe<
            { __typename?: 'JourneyRequestedDateValidationResult' } & Pick<
              GraphQLTypes.JourneyRequestedDateValidationResult,
              'value' | 'reason'
            >
          >;
          delays: Array<
            { __typename?: 'JourneyShippingMilestoneDelay' } & Pick<GraphQLTypes.JourneyShippingMilestoneDelay, 'id' | 'days'> & {
                reason?: GraphQLTypes.Maybe<
                  { __typename?: 'ShippingDelayReason' } & Pick<GraphQLTypes.ShippingDelayReason, 'id' | 'reason'>
                >;
              }
          >;
          initialEstimate?: GraphQLTypes.Maybe<
            { __typename?: 'LocalDateTimeRange' } & {
              startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>;
              endDateTime?: GraphQLTypes.Maybe<
                { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
              >;
            }
          >;
          latestEstimate?: GraphQLTypes.Maybe<
            { __typename?: 'LocalDateTimeRange' } & {
              startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>;
              endDateTime?: GraphQLTypes.Maybe<
                { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
              >;
            }
          >;
          planned?: GraphQLTypes.Maybe<
            { __typename?: 'LocalDateTimeRange' } & {
              endDateTime?: GraphQLTypes.Maybe<
                { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
              >;
              startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>;
            }
          >;
          requested?: GraphQLTypes.Maybe<
            { __typename?: 'LocalDateTimeRange' } & {
              endDateTime?: GraphQLTypes.Maybe<
                { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
              >;
              startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>;
            }
          >;
        }
    >;
    terminal?: GraphQLTypes.Maybe<
      { __typename?: 'Terminal' } & Pick<GraphQLTypes.Terminal, 'id' | 'label' | 'terminalTypes' | 'timeZone' | 'unlocode'>
    >;
    warehouse?: GraphQLTypes.Maybe<
      { __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id' | 'timeZone'> & {
          businessHours?: GraphQLTypes.Maybe<
            { __typename?: 'BusinessHours' } & {
              monday?: GraphQLTypes.Maybe<{ __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>>;
              tuesday?: GraphQLTypes.Maybe<{ __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>>;
              wednesday?: GraphQLTypes.Maybe<
                { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
              >;
              thursday?: GraphQLTypes.Maybe<{ __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>>;
              friday?: GraphQLTypes.Maybe<{ __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>>;
              saturday?: GraphQLTypes.Maybe<{ __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>>;
              sunday?: GraphQLTypes.Maybe<{ __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>>;
            }
          >;
          label?: GraphQLTypes.Maybe<
            { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>
          >;
        }
    >;
  };

export type CargoOverviewQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type CargoOverviewQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<
            GraphQLTypes.Booking,
            | 'accountUuid'
            | 'canToggleCollection'
            | 'isCollectionEnabled'
            | 'masterBillOfLading'
            | 'modeOfTransport'
            | 'zencargoReference'
          > & {
              canAddCargo?: GraphQLTypes.Maybe<
                { __typename?: 'CanAddCargoResult' } & Pick<GraphQLTypes.CanAddCargoResult, 'value' | 'reason'>
              >;
              canManageCargo: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canToggleCollectionCurrently?: GraphQLTypes.Maybe<
                { __typename?: 'JourneyToggleCollectionValidationResult' } & Pick<
                  GraphQLTypes.JourneyToggleCollectionValidationResult,
                  'value' | 'reason'
                >
              >;
              canViewShipmentTracking: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              calculatedInfo: { __typename?: 'CalculatedInfo' } & {
                cargoSummary?: GraphQLTypes.Maybe<
                  { __typename?: 'CargoSummary' } & Pick<GraphQLTypes.CargoSummary, 'highestRiskLevel'> & {
                      totalValueOfGoods?: GraphQLTypes.Maybe<
                        Array<{ __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'currency' | 'value'>>
                      >;
                    }
                >;
              };
              cargo?: GraphQLTypes.Maybe<
                { __typename?: 'Cargo' } & Pick<GraphQLTypes.Cargo, 'mode'> & {
                    cargoItems?: GraphQLTypes.Maybe<
                      Array<
                        | ({ __typename?: 'CargoContainerType' } & Pick<
                            GraphQLTypes.CargoContainerType,
                            | 'containerType'
                            | 'containerNumber'
                            | 'containerSealNumber'
                            | 'dryStandard'
                            | 'garmentsOnHangers'
                            | 'flatRack'
                            | 'openTop'
                            | 'overweight'
                            | 'cbm'
                            | 'hazardous'
                            | 'id'
                            | 'looseCargoType'
                            | 'quantity'
                            | 'reefer'
                            | 'type'
                          > & {
                              collection?: GraphQLTypes.Maybe<
                                { __typename?: 'CollectionDetails' } & Pick<
                                  GraphQLTypes.CollectionDetails,
                                  'id' | 'vehiclePlateNumber'
                                > & {
                                    location?: GraphQLTypes.Maybe<
                                      | ({ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id'> & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                            >;
                                          })
                                      | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksLegacyOrgLoc,
                                          'id'
                                        > & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                            >;
                                          })
                                    >;
                                  }
                              >;
                              delivery?: GraphQLTypes.Maybe<
                                { __typename?: 'DeliveryDetails' } & Pick<GraphQLTypes.DeliveryDetails, 'id'> & {
                                    location?: GraphQLTypes.Maybe<
                                      | ({ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id'> & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                            >;
                                          })
                                      | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksLegacyOrgLoc,
                                          'id'
                                        > & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                            >;
                                          })
                                    >;
                                  }
                              >;
                              grossWeight?: GraphQLTypes.Maybe<
                                { __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>
                              >;
                            })
                        | ({ __typename?: 'CargoLooseCargoType' } & Pick<
                            GraphQLTypes.CargoLooseCargoType,
                            | 'containerNumber'
                            | 'palletType'
                            | 'stackable'
                            | 'trailerId'
                            | 'cbm'
                            | 'hazardous'
                            | 'id'
                            | 'looseCargoType'
                            | 'quantity'
                            | 'reefer'
                            | 'type'
                          > & {
                              chargeableWeight?: GraphQLTypes.Maybe<
                                { __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>
                              >;
                              dimensions?: GraphQLTypes.Maybe<
                                { __typename?: 'CargoDimensionsType' } & Pick<
                                  GraphQLTypes.CargoDimensionsType,
                                  'length' | 'width' | 'height' | 'metric'
                                >
                              >;
                              collection?: GraphQLTypes.Maybe<
                                { __typename?: 'CollectionDetails' } & Pick<
                                  GraphQLTypes.CollectionDetails,
                                  'id' | 'vehiclePlateNumber'
                                > & {
                                    location?: GraphQLTypes.Maybe<
                                      | ({ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id'> & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                            >;
                                          })
                                      | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksLegacyOrgLoc,
                                          'id'
                                        > & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                            >;
                                          })
                                    >;
                                  }
                              >;
                              delivery?: GraphQLTypes.Maybe<
                                { __typename?: 'DeliveryDetails' } & Pick<GraphQLTypes.DeliveryDetails, 'id'> & {
                                    location?: GraphQLTypes.Maybe<
                                      | ({ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id'> & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                            >;
                                          })
                                      | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksLegacyOrgLoc,
                                          'id'
                                        > & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                            >;
                                          })
                                    >;
                                  }
                              >;
                              grossWeight?: GraphQLTypes.Maybe<
                                { __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>
                              >;
                            })
                        | ({ __typename?: 'CargoVehicleType' } & Pick<
                            GraphQLTypes.CargoVehicleType,
                            | 'healthCertificate'
                            | 'tailLift'
                            | 'trailerId'
                            | 'riskLevel'
                            | 'vehicleType'
                            | 'cbm'
                            | 'hazardous'
                            | 'id'
                            | 'looseCargoType'
                            | 'quantity'
                            | 'reefer'
                            | 'type'
                          > & {
                              dimensions?: GraphQLTypes.Maybe<
                                { __typename?: 'CargoDimensionsType' } & Pick<
                                  GraphQLTypes.CargoDimensionsType,
                                  'length' | 'width' | 'height' | 'metric'
                                >
                              >;
                              collection?: GraphQLTypes.Maybe<
                                { __typename?: 'CollectionDetails' } & Pick<
                                  GraphQLTypes.CollectionDetails,
                                  'id' | 'vehiclePlateNumber'
                                > & {
                                    location?: GraphQLTypes.Maybe<
                                      | ({ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id'> & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                            >;
                                          })
                                      | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksLegacyOrgLoc,
                                          'id'
                                        > & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                            >;
                                          })
                                    >;
                                  }
                              >;
                              delivery?: GraphQLTypes.Maybe<
                                { __typename?: 'DeliveryDetails' } & Pick<GraphQLTypes.DeliveryDetails, 'id'> & {
                                    location?: GraphQLTypes.Maybe<
                                      | ({ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id'> & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                            >;
                                          })
                                      | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksLegacyOrgLoc,
                                          'id'
                                        > & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                            >;
                                          })
                                    >;
                                  }
                              >;
                              grossWeight?: GraphQLTypes.Maybe<
                                { __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>
                              >;
                            })
                      >
                    >;
                  }
              >;
              cargoJourneys?: GraphQLTypes.Maybe<
                { __typename?: 'CargoJourneys' } & {
                  cargoReadyDate?: GraphQLTypes.Maybe<
                    { __typename?: 'JourneyShippingCargoReadyDateMilestone' } & Pick<
                      GraphQLTypes.JourneyShippingCargoReadyDateMilestone,
                      'completed' | 'id' | 'name'
                    > & {
                        latestEstimate?: GraphQLTypes.Maybe<
                          { __typename?: 'LocalDateTimeRange' } & {
                            startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                              GraphQLTypes.LocalDateTimeType,
                              'date' | 'time'
                            >;
                          }
                        >;
                      }
                  >;
                  journeys: Array<
                    { __typename?: 'CargoJourney' } & {
                      cargo?: GraphQLTypes.Maybe<{ __typename?: 'CoreCargo' } & Pick<GraphQLTypes.CoreCargo, 'id'>>;
                      journey?: GraphQLTypes.Maybe<
                        { __typename?: 'Journey' } & {
                          lastUpdated?: GraphQLTypes.Maybe<
                            { __typename?: 'JourneyLastUpdated' } & Pick<
                              GraphQLTypes.JourneyLastUpdated,
                              'updatedAt' | 'updatedBySource'
                            > & {
                                updatedBy?: GraphQLTypes.Maybe<
                                  { __typename?: 'NetworksContact' } & Pick<
                                    GraphQLTypes.NetworksContact,
                                    'id' | 'firstName' | 'lastName'
                                  >
                                >;
                              }
                          >;
                          legs?: GraphQLTypes.Maybe<
                            Array<
                              { __typename?: 'JourneyLeg' } & Pick<
                                GraphQLTypes.JourneyLeg,
                                'canManagePlannedDates' | 'id' | 'modeOfTransport' | 'transitTimeInMinutes' | 'type'
                              > & {
                                  from: { __typename?: 'JourneyStop' } & Pick<
                                    GraphQLTypes.JourneyStop,
                                    | 'canUpdateLocation'
                                    | 'canUpdateReference'
                                    | 'canViewReference'
                                    | 'id'
                                    | 'locationType'
                                    | 'reference'
                                    | 'stopAction'
                                  > & {
                                      shippingMilestones: Array<
                                        { __typename?: 'JourneyShippingMilestone' } & Pick<
                                          GraphQLTypes.JourneyShippingMilestone,
                                          | 'canManageDates'
                                          | 'canManageDelays'
                                          | 'canUpdateActualDate'
                                          | 'canUpdatePlannedDate'
                                          | 'canUpdateRequestedBy'
                                          | 'canUpdateRequestedDate'
                                          | 'category'
                                          | 'completed'
                                          | 'dateType'
                                          | 'delayInDays'
                                          | 'id'
                                          | 'milestoneType'
                                          | 'name'
                                          | 'requestedBy'
                                        > & {
                                            actual?: GraphQLTypes.Maybe<
                                              { __typename?: 'LocalDateTimeType' } & Pick<
                                                GraphQLTypes.LocalDateTimeType,
                                                'date' | 'time'
                                              >
                                            >;
                                            canManageDatesCurrently?: GraphQLTypes.Maybe<
                                              { __typename?: 'JourneyManageDatesValidationResult' } & Pick<
                                                GraphQLTypes.JourneyManageDatesValidationResult,
                                                'reason' | 'value'
                                              >
                                            >;
                                            canRemoveActualDateCurrently?: GraphQLTypes.Maybe<
                                              { __typename?: 'JourneyRemoveActualDateValidationResult' } & Pick<
                                                GraphQLTypes.JourneyRemoveActualDateValidationResult,
                                                'reason' | 'value'
                                              >
                                            >;
                                            canRemovePlannedDateCurrently?: GraphQLTypes.Maybe<
                                              { __typename?: 'JourneyRemovePlannedDateValidationResult' } & Pick<
                                                GraphQLTypes.JourneyRemovePlannedDateValidationResult,
                                                'reason' | 'value'
                                              >
                                            >;
                                            canRemoveRequestedDateCurrently?: GraphQLTypes.Maybe<
                                              { __typename?: 'JourneyRemoveRequestedDateValidationResult' } & Pick<
                                                GraphQLTypes.JourneyRemoveRequestedDateValidationResult,
                                                'reason' | 'value'
                                              >
                                            >;
                                            canUpdateActualDateCurrently?: GraphQLTypes.Maybe<
                                              { __typename?: 'JourneyActualDateValidationResult' } & Pick<
                                                GraphQLTypes.JourneyActualDateValidationResult,
                                                'value' | 'reason'
                                              >
                                            >;
                                            canUpdatePlannedDateCurrently?: GraphQLTypes.Maybe<
                                              { __typename?: 'JourneyPlannedDateValidationResult' } & Pick<
                                                GraphQLTypes.JourneyPlannedDateValidationResult,
                                                'value' | 'reason'
                                              >
                                            >;
                                            canUpdateRequestedDateCurrently?: GraphQLTypes.Maybe<
                                              { __typename?: 'JourneyRequestedDateValidationResult' } & Pick<
                                                GraphQLTypes.JourneyRequestedDateValidationResult,
                                                'value' | 'reason'
                                              >
                                            >;
                                            delays: Array<
                                              { __typename?: 'JourneyShippingMilestoneDelay' } & Pick<
                                                GraphQLTypes.JourneyShippingMilestoneDelay,
                                                'id' | 'days'
                                              > & {
                                                  reason?: GraphQLTypes.Maybe<
                                                    { __typename?: 'ShippingDelayReason' } & Pick<
                                                      GraphQLTypes.ShippingDelayReason,
                                                      'id' | 'reason'
                                                    >
                                                  >;
                                                }
                                            >;
                                            initialEstimate?: GraphQLTypes.Maybe<
                                              { __typename?: 'LocalDateTimeRange' } & {
                                                startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                                  GraphQLTypes.LocalDateTimeType,
                                                  'date' | 'time'
                                                >;
                                                endDateTime?: GraphQLTypes.Maybe<
                                                  { __typename?: 'LocalDateTimeType' } & Pick<
                                                    GraphQLTypes.LocalDateTimeType,
                                                    'date' | 'time'
                                                  >
                                                >;
                                              }
                                            >;
                                            latestEstimate?: GraphQLTypes.Maybe<
                                              { __typename?: 'LocalDateTimeRange' } & {
                                                startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                                  GraphQLTypes.LocalDateTimeType,
                                                  'date' | 'time'
                                                >;
                                                endDateTime?: GraphQLTypes.Maybe<
                                                  { __typename?: 'LocalDateTimeType' } & Pick<
                                                    GraphQLTypes.LocalDateTimeType,
                                                    'date' | 'time'
                                                  >
                                                >;
                                              }
                                            >;
                                            planned?: GraphQLTypes.Maybe<
                                              { __typename?: 'LocalDateTimeRange' } & {
                                                endDateTime?: GraphQLTypes.Maybe<
                                                  { __typename?: 'LocalDateTimeType' } & Pick<
                                                    GraphQLTypes.LocalDateTimeType,
                                                    'date' | 'time'
                                                  >
                                                >;
                                                startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                                  GraphQLTypes.LocalDateTimeType,
                                                  'date' | 'time'
                                                >;
                                              }
                                            >;
                                            requested?: GraphQLTypes.Maybe<
                                              { __typename?: 'LocalDateTimeRange' } & {
                                                endDateTime?: GraphQLTypes.Maybe<
                                                  { __typename?: 'LocalDateTimeType' } & Pick<
                                                    GraphQLTypes.LocalDateTimeType,
                                                    'date' | 'time'
                                                  >
                                                >;
                                                startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                                  GraphQLTypes.LocalDateTimeType,
                                                  'date' | 'time'
                                                >;
                                              }
                                            >;
                                          }
                                      >;
                                      terminal?: GraphQLTypes.Maybe<
                                        { __typename?: 'Terminal' } & Pick<
                                          GraphQLTypes.Terminal,
                                          'id' | 'label' | 'terminalTypes' | 'timeZone' | 'unlocode'
                                        >
                                      >;
                                      warehouse?: GraphQLTypes.Maybe<
                                        { __typename?: 'NetworksOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksOrgLoc,
                                          'id' | 'timeZone'
                                        > & {
                                            businessHours?: GraphQLTypes.Maybe<
                                              { __typename?: 'BusinessHours' } & {
                                                monday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                tuesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                wednesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                thursday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                friday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                saturday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                sunday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                              }
                                            >;
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'short' | 'long'
                                              >
                                            >;
                                          }
                                      >;
                                    };
                                  to: { __typename?: 'JourneyStop' } & Pick<
                                    GraphQLTypes.JourneyStop,
                                    | 'canUpdateLocation'
                                    | 'canUpdateReference'
                                    | 'canViewReference'
                                    | 'id'
                                    | 'locationType'
                                    | 'reference'
                                    | 'stopAction'
                                  > & {
                                      shippingMilestones: Array<
                                        { __typename?: 'JourneyShippingMilestone' } & Pick<
                                          GraphQLTypes.JourneyShippingMilestone,
                                          | 'canManageDates'
                                          | 'canManageDelays'
                                          | 'canUpdateActualDate'
                                          | 'canUpdatePlannedDate'
                                          | 'canUpdateRequestedBy'
                                          | 'canUpdateRequestedDate'
                                          | 'category'
                                          | 'completed'
                                          | 'dateType'
                                          | 'delayInDays'
                                          | 'id'
                                          | 'milestoneType'
                                          | 'name'
                                          | 'requestedBy'
                                        > & {
                                            actual?: GraphQLTypes.Maybe<
                                              { __typename?: 'LocalDateTimeType' } & Pick<
                                                GraphQLTypes.LocalDateTimeType,
                                                'date' | 'time'
                                              >
                                            >;
                                            canManageDatesCurrently?: GraphQLTypes.Maybe<
                                              { __typename?: 'JourneyManageDatesValidationResult' } & Pick<
                                                GraphQLTypes.JourneyManageDatesValidationResult,
                                                'reason' | 'value'
                                              >
                                            >;
                                            canRemoveActualDateCurrently?: GraphQLTypes.Maybe<
                                              { __typename?: 'JourneyRemoveActualDateValidationResult' } & Pick<
                                                GraphQLTypes.JourneyRemoveActualDateValidationResult,
                                                'reason' | 'value'
                                              >
                                            >;
                                            canRemovePlannedDateCurrently?: GraphQLTypes.Maybe<
                                              { __typename?: 'JourneyRemovePlannedDateValidationResult' } & Pick<
                                                GraphQLTypes.JourneyRemovePlannedDateValidationResult,
                                                'reason' | 'value'
                                              >
                                            >;
                                            canRemoveRequestedDateCurrently?: GraphQLTypes.Maybe<
                                              { __typename?: 'JourneyRemoveRequestedDateValidationResult' } & Pick<
                                                GraphQLTypes.JourneyRemoveRequestedDateValidationResult,
                                                'reason' | 'value'
                                              >
                                            >;
                                            canUpdateActualDateCurrently?: GraphQLTypes.Maybe<
                                              { __typename?: 'JourneyActualDateValidationResult' } & Pick<
                                                GraphQLTypes.JourneyActualDateValidationResult,
                                                'value' | 'reason'
                                              >
                                            >;
                                            canUpdatePlannedDateCurrently?: GraphQLTypes.Maybe<
                                              { __typename?: 'JourneyPlannedDateValidationResult' } & Pick<
                                                GraphQLTypes.JourneyPlannedDateValidationResult,
                                                'value' | 'reason'
                                              >
                                            >;
                                            canUpdateRequestedDateCurrently?: GraphQLTypes.Maybe<
                                              { __typename?: 'JourneyRequestedDateValidationResult' } & Pick<
                                                GraphQLTypes.JourneyRequestedDateValidationResult,
                                                'value' | 'reason'
                                              >
                                            >;
                                            delays: Array<
                                              { __typename?: 'JourneyShippingMilestoneDelay' } & Pick<
                                                GraphQLTypes.JourneyShippingMilestoneDelay,
                                                'id' | 'days'
                                              > & {
                                                  reason?: GraphQLTypes.Maybe<
                                                    { __typename?: 'ShippingDelayReason' } & Pick<
                                                      GraphQLTypes.ShippingDelayReason,
                                                      'id' | 'reason'
                                                    >
                                                  >;
                                                }
                                            >;
                                            initialEstimate?: GraphQLTypes.Maybe<
                                              { __typename?: 'LocalDateTimeRange' } & {
                                                startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                                  GraphQLTypes.LocalDateTimeType,
                                                  'date' | 'time'
                                                >;
                                                endDateTime?: GraphQLTypes.Maybe<
                                                  { __typename?: 'LocalDateTimeType' } & Pick<
                                                    GraphQLTypes.LocalDateTimeType,
                                                    'date' | 'time'
                                                  >
                                                >;
                                              }
                                            >;
                                            latestEstimate?: GraphQLTypes.Maybe<
                                              { __typename?: 'LocalDateTimeRange' } & {
                                                startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                                  GraphQLTypes.LocalDateTimeType,
                                                  'date' | 'time'
                                                >;
                                                endDateTime?: GraphQLTypes.Maybe<
                                                  { __typename?: 'LocalDateTimeType' } & Pick<
                                                    GraphQLTypes.LocalDateTimeType,
                                                    'date' | 'time'
                                                  >
                                                >;
                                              }
                                            >;
                                            planned?: GraphQLTypes.Maybe<
                                              { __typename?: 'LocalDateTimeRange' } & {
                                                endDateTime?: GraphQLTypes.Maybe<
                                                  { __typename?: 'LocalDateTimeType' } & Pick<
                                                    GraphQLTypes.LocalDateTimeType,
                                                    'date' | 'time'
                                                  >
                                                >;
                                                startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                                  GraphQLTypes.LocalDateTimeType,
                                                  'date' | 'time'
                                                >;
                                              }
                                            >;
                                            requested?: GraphQLTypes.Maybe<
                                              { __typename?: 'LocalDateTimeRange' } & {
                                                endDateTime?: GraphQLTypes.Maybe<
                                                  { __typename?: 'LocalDateTimeType' } & Pick<
                                                    GraphQLTypes.LocalDateTimeType,
                                                    'date' | 'time'
                                                  >
                                                >;
                                                startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                                  GraphQLTypes.LocalDateTimeType,
                                                  'date' | 'time'
                                                >;
                                              }
                                            >;
                                          }
                                      >;
                                      terminal?: GraphQLTypes.Maybe<
                                        { __typename?: 'Terminal' } & Pick<
                                          GraphQLTypes.Terminal,
                                          'id' | 'label' | 'terminalTypes' | 'timeZone' | 'unlocode'
                                        >
                                      >;
                                      warehouse?: GraphQLTypes.Maybe<
                                        { __typename?: 'NetworksOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksOrgLoc,
                                          'id' | 'timeZone'
                                        > & {
                                            businessHours?: GraphQLTypes.Maybe<
                                              { __typename?: 'BusinessHours' } & {
                                                monday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                tuesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                wednesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                thursday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                friday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                saturday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                sunday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                              }
                                            >;
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'short' | 'long'
                                              >
                                            >;
                                          }
                                      >;
                                    };
                                  vehicle: { __typename?: 'JourneyVehicle' } & Pick<
                                    GraphQLTypes.JourneyVehicle,
                                    'id' | 'type'
                                  > & {
                                      properties?: GraphQLTypes.Maybe<
                                        Array<
                                          { __typename?: 'JourneyVehicleProperty' } & Pick<
                                            GraphQLTypes.JourneyVehicleProperty,
                                            'canUpdate' | 'canView' | 'name' | 'value'
                                          >
                                        >
                                      >;
                                    };
                                }
                            >
                          >;
                          postLegsShippingMilestones?: GraphQLTypes.Maybe<
                            Array<
                              { __typename?: 'JourneyShippingMilestone' } & Pick<
                                GraphQLTypes.JourneyShippingMilestone,
                                | 'canManageDates'
                                | 'canManageDelays'
                                | 'canUpdateActualDate'
                                | 'canUpdatePlannedDate'
                                | 'canUpdateRequestedBy'
                                | 'canUpdateRequestedDate'
                                | 'category'
                                | 'completed'
                                | 'dateType'
                                | 'delayInDays'
                                | 'id'
                                | 'milestoneType'
                                | 'name'
                                | 'requestedBy'
                              > & {
                                  actual?: GraphQLTypes.Maybe<
                                    { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
                                  >;
                                  canManageDatesCurrently?: GraphQLTypes.Maybe<
                                    { __typename?: 'JourneyManageDatesValidationResult' } & Pick<
                                      GraphQLTypes.JourneyManageDatesValidationResult,
                                      'reason' | 'value'
                                    >
                                  >;
                                  canRemoveActualDateCurrently?: GraphQLTypes.Maybe<
                                    { __typename?: 'JourneyRemoveActualDateValidationResult' } & Pick<
                                      GraphQLTypes.JourneyRemoveActualDateValidationResult,
                                      'reason' | 'value'
                                    >
                                  >;
                                  canRemovePlannedDateCurrently?: GraphQLTypes.Maybe<
                                    { __typename?: 'JourneyRemovePlannedDateValidationResult' } & Pick<
                                      GraphQLTypes.JourneyRemovePlannedDateValidationResult,
                                      'reason' | 'value'
                                    >
                                  >;
                                  canRemoveRequestedDateCurrently?: GraphQLTypes.Maybe<
                                    { __typename?: 'JourneyRemoveRequestedDateValidationResult' } & Pick<
                                      GraphQLTypes.JourneyRemoveRequestedDateValidationResult,
                                      'reason' | 'value'
                                    >
                                  >;
                                  canUpdateActualDateCurrently?: GraphQLTypes.Maybe<
                                    { __typename?: 'JourneyActualDateValidationResult' } & Pick<
                                      GraphQLTypes.JourneyActualDateValidationResult,
                                      'value' | 'reason'
                                    >
                                  >;
                                  canUpdatePlannedDateCurrently?: GraphQLTypes.Maybe<
                                    { __typename?: 'JourneyPlannedDateValidationResult' } & Pick<
                                      GraphQLTypes.JourneyPlannedDateValidationResult,
                                      'value' | 'reason'
                                    >
                                  >;
                                  canUpdateRequestedDateCurrently?: GraphQLTypes.Maybe<
                                    { __typename?: 'JourneyRequestedDateValidationResult' } & Pick<
                                      GraphQLTypes.JourneyRequestedDateValidationResult,
                                      'value' | 'reason'
                                    >
                                  >;
                                  delays: Array<
                                    { __typename?: 'JourneyShippingMilestoneDelay' } & Pick<
                                      GraphQLTypes.JourneyShippingMilestoneDelay,
                                      'id' | 'days'
                                    > & {
                                        reason?: GraphQLTypes.Maybe<
                                          { __typename?: 'ShippingDelayReason' } & Pick<
                                            GraphQLTypes.ShippingDelayReason,
                                            'id' | 'reason'
                                          >
                                        >;
                                      }
                                  >;
                                  initialEstimate?: GraphQLTypes.Maybe<
                                    { __typename?: 'LocalDateTimeRange' } & {
                                      startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                        GraphQLTypes.LocalDateTimeType,
                                        'date' | 'time'
                                      >;
                                      endDateTime?: GraphQLTypes.Maybe<
                                        { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >
                                      >;
                                    }
                                  >;
                                  latestEstimate?: GraphQLTypes.Maybe<
                                    { __typename?: 'LocalDateTimeRange' } & {
                                      startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                        GraphQLTypes.LocalDateTimeType,
                                        'date' | 'time'
                                      >;
                                      endDateTime?: GraphQLTypes.Maybe<
                                        { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >
                                      >;
                                    }
                                  >;
                                  planned?: GraphQLTypes.Maybe<
                                    { __typename?: 'LocalDateTimeRange' } & {
                                      endDateTime?: GraphQLTypes.Maybe<
                                        { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >
                                      >;
                                      startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                        GraphQLTypes.LocalDateTimeType,
                                        'date' | 'time'
                                      >;
                                    }
                                  >;
                                  requested?: GraphQLTypes.Maybe<
                                    { __typename?: 'LocalDateTimeRange' } & {
                                      endDateTime?: GraphQLTypes.Maybe<
                                        { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >
                                      >;
                                      startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                        GraphQLTypes.LocalDateTimeType,
                                        'date' | 'time'
                                      >;
                                    }
                                  >;
                                }
                            >
                          >;
                          preLegsShippingMilestones?: GraphQLTypes.Maybe<
                            Array<
                              { __typename?: 'JourneyShippingMilestone' } & Pick<
                                GraphQLTypes.JourneyShippingMilestone,
                                | 'canManageDates'
                                | 'canManageDelays'
                                | 'canUpdateActualDate'
                                | 'canUpdatePlannedDate'
                                | 'canUpdateRequestedBy'
                                | 'canUpdateRequestedDate'
                                | 'category'
                                | 'completed'
                                | 'dateType'
                                | 'delayInDays'
                                | 'id'
                                | 'milestoneType'
                                | 'name'
                                | 'requestedBy'
                              > & {
                                  actual?: GraphQLTypes.Maybe<
                                    { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
                                  >;
                                  canManageDatesCurrently?: GraphQLTypes.Maybe<
                                    { __typename?: 'JourneyManageDatesValidationResult' } & Pick<
                                      GraphQLTypes.JourneyManageDatesValidationResult,
                                      'reason' | 'value'
                                    >
                                  >;
                                  canRemoveActualDateCurrently?: GraphQLTypes.Maybe<
                                    { __typename?: 'JourneyRemoveActualDateValidationResult' } & Pick<
                                      GraphQLTypes.JourneyRemoveActualDateValidationResult,
                                      'reason' | 'value'
                                    >
                                  >;
                                  canRemovePlannedDateCurrently?: GraphQLTypes.Maybe<
                                    { __typename?: 'JourneyRemovePlannedDateValidationResult' } & Pick<
                                      GraphQLTypes.JourneyRemovePlannedDateValidationResult,
                                      'reason' | 'value'
                                    >
                                  >;
                                  canRemoveRequestedDateCurrently?: GraphQLTypes.Maybe<
                                    { __typename?: 'JourneyRemoveRequestedDateValidationResult' } & Pick<
                                      GraphQLTypes.JourneyRemoveRequestedDateValidationResult,
                                      'reason' | 'value'
                                    >
                                  >;
                                  canUpdateActualDateCurrently?: GraphQLTypes.Maybe<
                                    { __typename?: 'JourneyActualDateValidationResult' } & Pick<
                                      GraphQLTypes.JourneyActualDateValidationResult,
                                      'value' | 'reason'
                                    >
                                  >;
                                  canUpdatePlannedDateCurrently?: GraphQLTypes.Maybe<
                                    { __typename?: 'JourneyPlannedDateValidationResult' } & Pick<
                                      GraphQLTypes.JourneyPlannedDateValidationResult,
                                      'value' | 'reason'
                                    >
                                  >;
                                  canUpdateRequestedDateCurrently?: GraphQLTypes.Maybe<
                                    { __typename?: 'JourneyRequestedDateValidationResult' } & Pick<
                                      GraphQLTypes.JourneyRequestedDateValidationResult,
                                      'value' | 'reason'
                                    >
                                  >;
                                  delays: Array<
                                    { __typename?: 'JourneyShippingMilestoneDelay' } & Pick<
                                      GraphQLTypes.JourneyShippingMilestoneDelay,
                                      'id' | 'days'
                                    > & {
                                        reason?: GraphQLTypes.Maybe<
                                          { __typename?: 'ShippingDelayReason' } & Pick<
                                            GraphQLTypes.ShippingDelayReason,
                                            'id' | 'reason'
                                          >
                                        >;
                                      }
                                  >;
                                  initialEstimate?: GraphQLTypes.Maybe<
                                    { __typename?: 'LocalDateTimeRange' } & {
                                      startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                        GraphQLTypes.LocalDateTimeType,
                                        'date' | 'time'
                                      >;
                                      endDateTime?: GraphQLTypes.Maybe<
                                        { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >
                                      >;
                                    }
                                  >;
                                  latestEstimate?: GraphQLTypes.Maybe<
                                    { __typename?: 'LocalDateTimeRange' } & {
                                      startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                        GraphQLTypes.LocalDateTimeType,
                                        'date' | 'time'
                                      >;
                                      endDateTime?: GraphQLTypes.Maybe<
                                        { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >
                                      >;
                                    }
                                  >;
                                  planned?: GraphQLTypes.Maybe<
                                    { __typename?: 'LocalDateTimeRange' } & {
                                      endDateTime?: GraphQLTypes.Maybe<
                                        { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >
                                      >;
                                      startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                        GraphQLTypes.LocalDateTimeType,
                                        'date' | 'time'
                                      >;
                                    }
                                  >;
                                  requested?: GraphQLTypes.Maybe<
                                    { __typename?: 'LocalDateTimeRange' } & {
                                      endDateTime?: GraphQLTypes.Maybe<
                                        { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >
                                      >;
                                      startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                        GraphQLTypes.LocalDateTimeType,
                                        'date' | 'time'
                                      >;
                                    }
                                  >;
                                }
                            >
                          >;
                        }
                      >;
                    }
                  >;
                }
              >;
              coreCargos?: GraphQLTypes.Maybe<
                Array<
                  { __typename?: 'CoreCargo' } & Pick<
                    GraphQLTypes.CoreCargo,
                    | 'cargoType'
                    | 'containerNumber'
                    | 'containerSealNumber'
                    | 'dryStandard'
                    | 'flatRack'
                    | 'healthCertificate'
                    | 'id'
                    | 'garmentsOnHangers'
                    | 'hazardous'
                    | 'openTop'
                    | 'overweight'
                    | 'refrigerated'
                    | 'quantity'
                    | 'riskLevel'
                    | 'stackable'
                    | 'tailLift'
                    | 'trailerId'
                  > & {
                      cargoRelations?: GraphQLTypes.Maybe<
                        Array<
                          { __typename?: 'CargoRelation' } & Pick<GraphQLTypes.CargoRelation, 'startTimestamp'> & {
                              inputCargo?: GraphQLTypes.Maybe<
                                { __typename?: 'CoreCargo' } & Pick<GraphQLTypes.CoreCargo, 'cargoType' | 'id' | 'quantity'>
                              >;
                            }
                        >
                      >;
                      chargeableWeight?: GraphQLTypes.Maybe<
                        { __typename?: 'CargoWeight' } & Pick<GraphQLTypes.CargoWeight, 'value' | 'unit'>
                      >;
                      dimensions?: GraphQLTypes.Maybe<
                        { __typename?: 'Dimensions' } & Pick<GraphQLTypes.Dimensions, 'width' | 'height' | 'length' | 'unit'>
                      >;
                      grossWeight?: GraphQLTypes.Maybe<
                        { __typename?: 'CargoWeight' } & Pick<GraphQLTypes.CargoWeight, 'value' | 'unit'>
                      >;
                      valueOfGoods?: GraphQLTypes.Maybe<
                        { __typename?: 'ValueOfGoods' } & Pick<GraphQLTypes.ValueOfGoods, 'value' | 'currency'>
                      >;
                      volume?: GraphQLTypes.Maybe<{ __typename?: 'Volume' } & Pick<GraphQLTypes.Volume, 'value' | 'unit'>>;
                    }
                >
              >;
              customer?: GraphQLTypes.Maybe<
                { __typename?: 'BookingCustomerType' } & Pick<GraphQLTypes.BookingCustomerType, 'uuid'>
              >;
            }
        >
      >
    >;
  };
};

export const JourneyShippingMilestoneFragmentDoc = /* #__PURE__ */ gql`
  fragment JourneyShippingMilestone on JourneyShippingMilestone {
    actual {
      date
      time
    }
    canManageDates
    canManageDatesCurrently {
      reason
      value
    }
    canManageDelays
    canRemoveActualDateCurrently {
      reason
      value
    }
    canRemovePlannedDateCurrently {
      reason
      value
    }
    canRemoveRequestedDateCurrently {
      reason
      value
    }
    canUpdateActualDate
    canUpdateActualDateCurrently {
      value
      reason
    }
    canUpdatePlannedDate
    canUpdatePlannedDateCurrently {
      value
      reason
    }
    canUpdateRequestedBy
    canUpdateRequestedDate
    canUpdateRequestedDateCurrently {
      value
      reason
    }
    category
    completed
    dateType
    delayInDays
    delays {
      id
      days
      reason {
        id
        reason
      }
    }
    id
    initialEstimate {
      startDateTime {
        date
        time
      }
      endDateTime {
        date
        time
      }
    }
    latestEstimate {
      startDateTime {
        date
        time
      }
      endDateTime {
        date
        time
      }
    }
    milestoneType
    name
    planned {
      endDateTime {
        date
        time
      }
      startDateTime {
        date
        time
      }
    }
    requested {
      endDateTime {
        date
        time
      }
      startDateTime {
        date
        time
      }
    }
    requestedBy
  }
`;
export const JourneyStopFragmentDoc = /* #__PURE__ */ gql`
  fragment JourneyStop on JourneyStop {
    canUpdateLocation
    canUpdateReference
    canViewReference
    id
    locationType
    reference
    shippingMilestones {
      ...JourneyShippingMilestone
    }
    stopAction
    terminal {
      id
      label
      terminalTypes
      timeZone
      unlocode
    }
    warehouse {
      businessHours {
        ...BusinessHours
      }
      id
      label {
        short
        long
      }
      timeZone
    }
  }
  ${JourneyShippingMilestoneFragmentDoc}
  ${BusinessHoursFragmentDoc}
`;
export const CargoOverviewDocument = /* #__PURE__ */ gql`
  query cargoOverview($zencargoReference: String!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        accountUuid
        canAddCargo {
          value
          reason
        }
        canManageCargo {
          value
        }
        canToggleCollection
        canToggleCollectionCurrently {
          value
          reason
        }
        canViewShipmentTracking {
          value
        }
        calculatedInfo {
          cargoSummary {
            highestRiskLevel
            totalValueOfGoods {
              currency
              value
            }
          }
        }
        cargo {
          cargoItems {
            cbm
            collection {
              id
              location {
                id
                label {
                  long
                }
              }
              vehiclePlateNumber
            }
            delivery {
              id
              location {
                id
                label {
                  long
                }
              }
            }
            grossWeight {
              metric
              value
            }
            hazardous
            id
            looseCargoType
            quantity
            reefer
            type
            ... on CargoContainerType {
              containerType
              containerNumber
              containerSealNumber
              dryStandard
              garmentsOnHangers
              flatRack
              openTop
              overweight
            }
            ... on CargoLooseCargoType {
              chargeableWeight {
                metric
                value
              }
              containerNumber
              palletType
              stackable
              dimensions {
                length
                width
                height
                metric
              }
              trailerId
            }
            ... on CargoVehicleType {
              dimensions {
                length
                width
                height
                metric
              }
              healthCertificate
              tailLift
              trailerId
              riskLevel
              vehicleType
            }
          }
          mode
        }
        cargoJourneys {
          cargoReadyDate {
            completed
            id
            latestEstimate {
              startDateTime {
                date
                time
              }
            }
            name
          }
          journeys {
            cargo {
              id
            }
            journey {
              lastUpdated {
                updatedAt
                updatedBy {
                  id
                  firstName
                  lastName
                }
                updatedBySource
              }
              legs {
                canManagePlannedDates
                from {
                  ...JourneyStop
                }
                id
                modeOfTransport
                to {
                  ...JourneyStop
                }
                transitTimeInMinutes
                type
                vehicle {
                  id
                  properties {
                    canUpdate
                    canView
                    name
                    value
                  }
                  type
                }
              }
              postLegsShippingMilestones {
                ...JourneyShippingMilestone
              }
              preLegsShippingMilestones {
                ...JourneyShippingMilestone
              }
            }
          }
        }
        coreCargos {
          cargoRelations {
            inputCargo {
              cargoType
              id
              quantity
            }
            startTimestamp
          }
          cargoType
          chargeableWeight {
            value
            unit
          }
          containerNumber
          containerSealNumber
          dimensions {
            width
            height
            length
            unit
          }
          dryStandard
          flatRack
          healthCertificate
          id
          garmentsOnHangers
          grossWeight {
            value
            unit
          }
          hazardous
          openTop
          overweight
          refrigerated
          quantity
          riskLevel
          stackable
          tailLift
          trailerId
          valueOfGoods {
            value
            currency
          }
          volume {
            value
            unit
          }
        }
        customer {
          uuid
        }
        isCollectionEnabled
        masterBillOfLading
        modeOfTransport
        zencargoReference
      }
    }
  }
  ${JourneyStopFragmentDoc}
  ${JourneyShippingMilestoneFragmentDoc}
`;

/**
 * __useCargoOverviewQuery__
 *
 * To run a query within a React component, call `useCargoOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useCargoOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCargoOverviewQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useCargoOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<CargoOverviewQueryResult, CargoOverviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<CargoOverviewQueryResult, CargoOverviewQueryVariables>(CargoOverviewDocument, options);
}
export function useCargoOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CargoOverviewQueryResult, CargoOverviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<CargoOverviewQueryResult, CargoOverviewQueryVariables>(CargoOverviewDocument, options);
}
export type CargoOverviewQueryHookResult = ReturnType<typeof useCargoOverviewQuery>;
export type CargoOverviewLazyQueryHookResult = ReturnType<typeof useCargoOverviewLazyQuery>;
