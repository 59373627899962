import { type FC, useState } from 'react';

import { Dialog, IconButton } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import useTracking from '@zen/utils/hooks/useTracking';
import { performMutation } from '@zen/utils/performMutation';
import type { Optional } from '@zen/utils/typescript';

import { getLegacyCargoItemType } from '../cargoDictionaryMapping.helper';
import { DeleteCargoItemMutationVariables, useDeleteCargoItemMutation } from '../graphql';
import { CargoManagementTrackingAction, CargoManagementTrackingCategory, CoreCargoType } from '../types';

interface Props {
  cargoId: string;
  cargoType: Optional<CoreCargoType>;
}

const DeleteCargoButton: FC<Props> = ({ cargoId, cargoType }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [deleteCargo, { loading: isLoading }] = useDeleteCargoItemMutation();
  const { trackEvent } = useTracking();
  const { addError, addSuccess } = useNotification();

  const handleOpenModal = (): void => setShowConfirmationModal(true);
  const handleCloseModal = (): void => setShowConfirmationModal(false);

  const handleConfirm = async (): Promise<void> => {
    const variables: DeleteCargoItemMutationVariables = {
      input: {
        cargoId
      }
    };

    await performMutation({
      mutationFn: () =>
        deleteCargo({
          variables,
          refetchQueries: ['cargoOverview', 'cargoJourneysSummary', 'oceanTrackingStatus']
        }),
      onError: () => addError(),
      onSuccess: () => {
        trackEvent({
          category: CargoManagementTrackingCategory,
          action: CargoManagementTrackingAction.DELETE_CARGO_ITEM,
          label: (cargoType && getLegacyCargoItemType(cargoType)) || ''
        });
        handleCloseModal();
        addSuccess('Cargo has been deleted.');
      }
    });
  };

  return (
    <>
      <IconButton icon="zicon-trash" onClick={handleOpenModal} size="medium" title="Delete" variant="secondary" />
      <Dialog
        confirmLabel="Delete"
        header="Delete cargo item"
        isLoading={isLoading}
        isOpen={showConfirmationModal}
        message="Are you sure you want to permanently delete this cargo item? This means that you will lose all your current cargo and route data."
        onClose={handleCloseModal}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default DeleteCargoButton;
