import type { FC } from 'react';
import type { CloseButtonProps } from 'react-toastify/dist/components';

import { Icon } from '@zen/DesignSystem';

const CloseButton: FC<CloseButtonProps> = ({ closeToast }) => {
  return <Icon className="mt-1 ml-6 text-xs text-white cursor-pointer" icon="zicon-close" onClick={closeToast} />;
};

export default CloseButton;
