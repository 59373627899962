import { forwardRef, ReactNode, Ref } from 'react';

import { TimeInput } from '@zen/DesignSystem';

import FormField, { FormFieldProps } from '../FormField';
import type { FormInstance } from '../types';

type TimeInputField = {
  name: string;
  onChange: () => void;
  value: string;
};

interface Props extends FormFieldProps {
  inline?: boolean;
  noMargin?: boolean;
  ref?: Ref<HTMLInputElement>;
}

const FormTimeInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { inline, ...otherProps } = props;

  return (
    <FormField {...otherProps}>
      {(field: TimeInputField, form: FormInstance<unknown>): ReactNode => {
        const handleChange = (timeInput: string): void => {
          form.setFieldValue(field.name, timeInput);
        };

        return <TimeInput {...field} ref={ref} id={field.name} onChange={handleChange} variant={inline ? 'inline' : 'default'} />;
      }}
    </FormField>
  );
});

export default FormTimeInput;
