import cx from 'classnames';
import type { FC } from 'react';

import { FormArray, FormArrayHelpers, FormCurrencyInput, FormInput, useForm } from '@zen/Components';
import { ChargeGroupItemType, ChargeType, FormChargeTypeSelect } from '@zen/CostTracking';
import { Headline, IconButton } from '@zen/DesignSystem';
import { useRateCardContext } from '@zen/RateCards/RateCardContext';
import type { Nullable, Optional } from '@zen/utils/typescript';

import FormCargoOptionsSelect from '../../../components/FormCargoOptionsSelect';
import FormChargeBasisSelect from '../../../components/FormChargeBasisSelect';
import type { ChargeBasisGroup, ChargeItemFormInitialValues } from '../../types';
import { getEmptyChargeItem } from './helpers';
import type { Applicability } from './types';

interface Props {
  chargeItems: ChargeItemFormInitialValues[];
  chargeType?: ChargeType;
  chargeTypeApplicability?: Applicability;
  chargeTypeSearchGroups?: ChargeGroupItemType[];
  showChargeType?: boolean;
}

const RateCardChargeFormItems: FC<Props> = (props) => {
  const { chargeItems, chargeType, chargeTypeApplicability, chargeTypeSearchGroups, showChargeType = true } = props;
  const { setFieldValue } = useForm<ChargeItemFormInitialValues>();
  const shouldRenderDeleteButton: boolean = chargeItems.length > 1;
  const { cargoType, modeOfTransport } = useRateCardContext();

  return (
    <>
      <Headline level={4}>Charge items</Headline>
      <FormArray
        addButtonText="Add charge item"
        empty={getEmptyChargeItem(chargeType?.name)}
        isCompact={true}
        path="chargeItems"
        values={chargeItems}
      >
        {({ index, getFieldName, remove, value }: FormArrayHelpers<ChargeItemFormInitialValues>) => {
          const basisGroup: Optional<ChargeBasisGroup> = chargeType?.basisGroup || value.chargeType?.basisGroup;
          const isChargeBasisDisabled: boolean = chargeType ? !chargeType?.id : !value.chargeType;
          const classNames: string = cx(
            {
              'pr-14': !shouldRenderDeleteButton,
              'grid-cols-5': showChargeType,
              'grid-cols-4': !showChargeType
            },
            'grid flex-1 gap-4 mt-2'
          );

          const handleChargeTypeChange = (chargeValue: Nullable<ChargeType>) => {
            setFieldValue(`chargeItems.[${index}].chargeType`, chargeValue);
            setFieldValue(`chargeItems.[${index}].chargeName`, chargeValue?.name);
          };

          if (!cargoType || !modeOfTransport) {
            return null;
          }

          return (
            <div key={index} className="flex gap-4">
              <div className={classNames}>
                {showChargeType && (
                  <FormChargeTypeSelect
                    applicability={chargeTypeApplicability}
                    label="Charge type"
                    name={getFieldName('chargeType')}
                    onChange={handleChargeTypeChange}
                    searchableChargeTypes={chargeTypeSearchGroups}
                  />
                )}
                <FormInput isOptional={true} label="Charge name" name={getFieldName('chargeName')} />
                <FormChargeBasisSelect
                  chargeBasisGroup={basisGroup}
                  isDisabled={isChargeBasisDisabled}
                  label="Basis"
                  name={getFieldName('chargeBasis')}
                />
                <FormCargoOptionsSelect isOptional={true} label="Cargo options" name={getFieldName('cargoOptions')} />
                <FormCurrencyInput
                  label="Unit price"
                  name={`${getFieldName('unitPrice')}`}
                  onChange={(newValue) => {
                    setFieldValue(getFieldName('unitPrice'), newValue);
                  }}
                />
              </div>
              {shouldRenderDeleteButton && (
                <div className="pt-8">
                  <IconButton icon="zicon-trash" onClick={remove} variant="secondary" />
                </div>
              )}
            </div>
          );
        }}
      </FormArray>
    </>
  );
};

export type { Props as RateCardChargeFormItemsProps };

export default RateCardChargeFormItems;
