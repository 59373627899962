import type { ReactNode } from 'react';

import EditRateCard from './EditRateCard';
import LegacyEditRateCard from './LegacyRateCards/EditRateCard';
import LegacyNewRateCard from './LegacyRateCards/NewRateCard';
import LegacyRateCardDetails from './LegacyRateCards/RateCardDetails';
import LegacyRateCards from './LegacyRateCards/RateCards';
import NewRateCard from './NewRateCard';
import RateCardDetails from './RateCardDetails/RateCardDetails';
import RateCards from './RateCards';

export interface RateCardsConfig {
  create: ReactNode;
  details: ReactNode;
  edit: ReactNode;
  index: ReactNode;
}

export const getRateCardsComponentsConfig = (rateCardsFlagIsOn: boolean): RateCardsConfig => {
  const legacyComponentsConfig: RateCardsConfig = {
    create: <LegacyNewRateCard />,
    details: <LegacyRateCardDetails />,
    edit: <LegacyEditRateCard />,
    index: <LegacyRateCards />
  };

  const componentsConfig: RateCardsConfig = {
    create: <NewRateCard />,
    details: <RateCardDetails />,
    edit: <EditRateCard />,
    index: <RateCards />
  };

  return rateCardsFlagIsOn ? componentsConfig : legacyComponentsConfig;
};
