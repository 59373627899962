import type { FC } from 'react';

import { Dialog } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import { useUnarchiveAccountLocationLinkMutation } from '../../graphql';

interface Props {
  accountId: string;
  isOpen: boolean;
  locationId: string;
  locationName: string;
  onClose: () => void;
}

const UnarchiveLocationModal: FC<Props> = (props) => {
  const { accountId, isOpen, onClose, locationId, locationName } = props;
  const { addError, addSuccess } = useNotification();
  const [unarchiveLocation, { loading }] = useUnarchiveAccountLocationLinkMutation({ refetchQueries: ['getAccountLocations'] });

  const handleArchiveLocation = async (id: string): Promise<void> => {
    await performMutation({
      mutationFn: () =>
        unarchiveLocation({
          variables: {
            input: {
              accountId,
              locationId: id
            }
          }
        }),
      onError: () =>
        addError('Location could not be unarchived.', { label: 'Try again', onClick: () => handleArchiveLocation(id) }),
      onSuccess: () => {
        addSuccess('Location was successfully unarchived.');
        onClose();
      }
    });
  };

  const handleConfirm = async (): Promise<void> => {
    if (locationId) {
      await handleArchiveLocation(locationId);
    }
  };

  return (
    <Dialog
      confirmLabel="Unarchive"
      header={`Unarchive ${locationName}?`}
      isLoading={loading}
      isOpen={isOpen}
      message="Once unarchived, it will become available for new bookings. Existing bookings associated with this location will remain unchanged."
      onClose={onClose}
      onConfirm={handleConfirm}
    />
  );
};

export default UnarchiveLocationModal;
export type { Props as UnarchiveLocationModalProps };
