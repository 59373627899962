import { prepareLocation } from '@zen/RateCards/components/helpers';
import { findPort } from '@zen/RateCards/PortCharges/PortChargeForm';
import { getEmptyChargeItem } from '@zen/RateCards/RateCardForm/components/RateCardChargeFormItems';
import type { ChargeItemFormValues } from '@zen/RateCards/RateCardForm/types';
import type { RateCardCharge } from '@zen/RateCards/reducer';

import type { Applicability, CostTrackingLocation, HaulageChargeFormInitialValues, HaulageChargeFormValues } from './types';

export const initialValues: HaulageChargeFormInitialValues = {
  fromLocation: null,
  toLocationId: null,
  chargeItems: [getEmptyChargeItem()]
};

export const preparePayload = (
  values: HaulageChargeFormValues,
  ports: CostTrackingLocation[],
  applicability: Applicability
): RateCardCharge[] => {
  const { fromLocation, toLocationId } = values;

  return values.chargeItems.map((chargeItem: ChargeItemFormValues) => {
    const { chargeBasis, cargoOptions, chargeName, chargeType, unitPrice } = chargeItem;

    return {
      applicability: [applicability],
      chargeBasis,
      cargoOptions,
      chargeType,
      chargeTypeId: chargeType?.id,
      currency: unitPrice.currency,
      fromLocation: prepareLocation(fromLocation),
      itemName: chargeName,
      toLocation: findPort(ports, toLocationId),
      unitPrice: unitPrice.value
    };
  });
};
