import cx from 'classnames';
import moment from 'moment';
import { FC, Fragment, useState } from 'react';

import ActivityFeedVisibleParties from '@zen/ActivityFeed/components/ActivityFeedVisibleParties';
import Message from '@zen/ActivityFeed/components/Message';
import Timestamp from '@zen/ActivityFeed/components/Timestamp';
import UserDetails from '@zen/ActivityFeed/components/UserDetails';
import { isCurrentUser } from '@zen/ActivityFeed/helpers/isCurrentUser';
import { checkPermission } from '@zen/Auth/authHelper';
import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import { useFeatureIsOn } from '@zen/FeatureFlag';
import { ActivityFeedItemTypeEnum } from '@zen/graphql/types.generated';
import useAccount from '@zen/utils/hooks/useAccount';

import type { Activity, TextMessage } from '../../types';
import TextMessageDelete from './TextMessageDelete';

interface Props {
  activityMetadata: Activity;
  className?: string;
  message: TextMessage;
  showUserData?: boolean;
}

const TextMessageItem: FC<Props> = (props) => {
  const { className, message: initialMessage, activityMetadata, showUserData = true } = props;
  const { user, legacyUser, itemType, userDetails } = activityMetadata;
  const { userProfile } = useAccount();
  const [message, setMessage] = useState<TextMessage>(initialMessage);
  const { content } = message;
  const isArchived = !!message.archivedAt;

  const { check } = useGlobalPermissions();
  const createAccountFlagIsOn: boolean = useFeatureIsOn('create-account');
  const canManageActivityFeed: boolean = createAccountFlagIsOn ? check('accounts.canCreateActivityFeed') : true;

  const canArchive = canManageActivityFeed && checkPermission<TextMessage>(message, 'canArchive');

  const email = user ? user.email : legacyUser.email;

  const isMyMessage = isCurrentUser(userProfile, email);
  const variant = isMyMessage ? 'secondary' : 'primary';
  const directionClassName = isMyMessage ? 'flex-row-reverse' : 'flex-row';
  const isZensei = itemType === ActivityFeedItemTypeEnum.ZENSEI_TEXT_MESSAGE;

  const classnames: string = cx(
    'flex flex-col space-y-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-500',
    directionClassName
  );

  const textMessageClasses: string = cx(
    {
      '-mt-6': !showUserData,
      'justify-end': isMyMessage,
      'justify-start': !isMyMessage
    },
    'flex group cursor-pointer mb-4 pl-4',
    className
  );

  const renderContent = () => {
    return content?.split('\n').map((line: string, i: number) => (
      <Fragment key={i}>
        {line}
        <br />
      </Fragment>
    ));
  };

  const handleArchive = () => {
    setMessage({
      ...message,
      archivedAt: moment().format()
    });
  };

  return (
    <div className={textMessageClasses}>
      <div>
        {showUserData && !isMyMessage && (
          <div
            className={`flex text-sm text-grey-dark mb-2 leading-normal items-center ${directionClassName}`}
            data-testid="user-data"
          >
            <UserDetails isZensei={isZensei} legacyUser={legacyUser} user={user} userDetails={userDetails} />
          </div>
        )}
        <div className={`flex items-center ${directionClassName} relative`}>
          {isArchived && (
            <Message className="italic" textClass="text-grey-base" variant={variant}>
              This message was deleted
            </Message>
          )}
          {!isArchived && (
            <div className="flex group flex-row-reverse">
              {canArchive && <TextMessageDelete id={message.id} onArchive={handleArchive} variant={variant} />}
              <Message variant={variant}>{renderContent()}</Message>
            </div>
          )}
          <div className={classnames}>
            <Timestamp date={activityMetadata.createdAt} />
            <ActivityFeedVisibleParties withTooltip={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextMessageItem;
