import type { FC, PropsWithChildren } from 'react';

import { ModeOfTransport } from '../types';
import { CargoOverviewContext, CargoOverviewContextData } from './CargoOverviewContext';

export const defaultContext: CargoOverviewContextData = {
  accountId: 'account-id',
  bookingStage: null,
  cargoCount: 1,
  cargoIds: ['cargo-id'],
  cargoMilestones: {},
  cargoPreLegsMilestones: {},
  cargoReadyDate: '2024-07-01',
  carriageLegs: [],
  isCollectionEnabled: true,
  modeOfTransport: ModeOfTransport.OCEAN,
  zencargoReference: 'ZTEST-1'
};

interface Props {
  context?: Partial<CargoOverviewContextData>;
}

const TestCargoOverviewContext: FC<PropsWithChildren<Props>> = ({ children, context = {} }) => {
  return <CargoOverviewContext.Provider value={{ ...defaultContext, ...context }}>{children}</CargoOverviewContext.Provider>;
};

export default TestCargoOverviewContext;
