import { FC, ReactElement, ReactNode, useEffect, useState } from 'react';

import OptionList from '@zen/Components/Filters/FilterComponents/components/OptionList';
import type { OptionListSection } from '@zen/Components/Filters/FilterComponents/components/OptionList/types';
import { Button, type Option, Popover, Tooltip } from '@zen/DesignSystem';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

interface Props {
  allOptionsLabel: string;
  buttonText: string;
  initialValues: string[];
  onApply: (selectedValues: string[]) => Promise<IOkOrErrorResult>;
  options: Option<string>[];
  renderPopoverInPortal?: boolean;
  searchable?: boolean;
}

const OptionsDropdown: FC<Props> = (props) => {
  const { allOptionsLabel, onApply, options, initialValues, renderPopoverInPortal, searchable = false, buttonText } = props;
  const [allOptionsSelected, setAllOptionsSelected] = useState<boolean>(initialValues.length === 0);
  const [selectedValues, setSelectedValues] = useState<string[]>(initialValues);
  const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState<boolean>(false);

  const noOptionsSelected: boolean = !allOptionsSelected && !selectedValues.length;
  const isSubmitButtonDisabled: boolean = inProgress || noOptionsSelected;

  const selectAllOptions = (): void => {
    setSelectedValues(options.map((option) => option.value));
  };

  useEffect(() => {
    const isAllOptionsSelected: boolean = initialValues.length === 0;

    setAllOptionsSelected(isAllOptionsSelected);

    if (isAllOptionsSelected) {
      selectAllOptions();
    }
  }, [isPopoverVisible, initialValues.length]);

  const togglePopoverVisibility = (): void => setIsPopoverVisible((isVisible) => !isVisible);

  const isSelected = (option: Option<string>): boolean => {
    return selectedValues.includes(option.value);
  };

  const deselectOption = (option: Option<string>): void => {
    const newValue: string[] = selectedValues.filter((selectedValue) => selectedValue !== option.value);

    setSelectedValues(newValue);
  };

  const selectOption = (option: Option<string>): void => {
    setSelectedValues([option.value, ...selectedValues]);
  };

  const handleSelect = (option: Option<string>): void => {
    if (isSelected(option)) {
      deselectOption(option);
    } else {
      selectOption(option);
    }
  };

  const createItems = (): OptionListSection[] => {
    const items: OptionListSection['items'] = options.map((option) => {
      return {
        ...option,
        disabled: allOptionsSelected,
        onClick: () => handleSelect(option),
        isSelected: selectedValues.includes(option.value)
      };
    });

    return [{ items: [{ label: allOptionsLabel, isSelected: allOptionsSelected, onClick: handleAllOptionsClick }] }, { items }];
  };

  const handleVisibilityChange = (): void => {
    setSelectedValues(initialValues);
    togglePopoverVisibility();
  };

  const handleAllOptionsClick = (): void => {
    if (!allOptionsSelected) {
      selectAllOptions();
    } else {
      setSelectedValues([]);
    }
    setAllOptionsSelected((selected) => !selected);
  };

  const handleSave = async (): Promise<void> => {
    setInProgress(true);

    const values: string[] = allOptionsSelected ? [] : selectedValues;

    await onApply(values);

    setInProgress(false);

    togglePopoverVisibility();
  };

  const submitButton: ReactNode = (
    <Button disabled={isSubmitButtonDisabled} isLoading={inProgress} onClick={handleSave} size="compact" variant="primary">
      Save changes
    </Button>
  );

  const renderSubmitButton = (): ReactNode => {
    if (isSubmitButtonDisabled && !inProgress) {
      return (
        <Tooltip placement="bottom-end" renderInPortal={true} tooltipContent="Assign at least 1 business unit">
          {submitButton}
        </Tooltip>
      );
    }

    return submitButton;
  };

  const list: ReactNode = (
    <div className="divide-y divide-solid divide-grey-lighter">
      <OptionList list={createItems()} searchable={searchable} selection="checkbox" />
      <div className="flex justify-end p-4 leading-normal">
        <div className="flex gap-4">
          <Button onClick={handleVisibilityChange} size="compact" variant="secondary">
            Cancel
          </Button>
          {renderSubmitButton()}
        </div>
      </div>
    </div>
  );

  const optionsButton: ReactElement = (
    <Popover
      isVisible={isPopoverVisible}
      onVisibilityChange={handleVisibilityChange}
      popover={list}
      popoverClassNames="border border-solid border-grey-lighter"
      renderInPortal={renderPopoverInPortal}
    >
      <Button disabled={!options.length} iconRight="zicon-chevron-down-small" size="compact" variant="ghost">
        {buttonText}
      </Button>
    </Popover>
  );

  return optionsButton;
};

export default OptionsDropdown;
export type { Props as OptionsDropdownProps };
