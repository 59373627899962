import { FC, useState } from 'react';

import { ContextMenu, type MenuItemType } from '@zen/DesignSystem';

import ArchiveLocationModal from '../ArchiveLocationModal';
import UnarchiveLocationModal from '../UnarchiveLocationModal';
import UpdateAccountHeadquartersModal from '../UpdateAccountHeadquartersModal';

interface Props {
  accountId: string;
  canArchiveLocation: boolean;
  canEdit: boolean;
  canUnarchiveLocation: boolean;
  companyName: string;
  isRegisteredLegalAddress: boolean;
  locationId: string;
  locationName: string;
}

const AccountLocationsActions: FC<Props> = (props: Props) => {
  const {
    accountId,
    canArchiveLocation,
    canEdit,
    canUnarchiveLocation,
    companyName,
    isRegisteredLegalAddress,
    locationId,
    locationName
  } = props;

  const [isAssignHQModalOpen, setAssignHQModalOpen] = useState<boolean>(false);
  const [isArchiveLocationModalOpen, setIsArchiveLocationModalOpen] = useState<boolean>(false);
  const [isUnarchiveLocationModalOpen, setIsUnarchiveLocationModalOpen] = useState<boolean>(false);

  const archiveLocationOption: MenuItemType = {
    label: 'Archive',
    onClick: () => setIsArchiveLocationModalOpen(true),
    icon: 'zicon-archive'
  };
  const unarchiveLocationOption: MenuItemType = {
    label: 'Unarchive',
    onClick: () => setIsUnarchiveLocationModalOpen(true),
    icon: 'zicon-unarchive'
  };
  const makeHQOption: MenuItemType = {
    disabled: isRegisteredLegalAddress,
    disabledItemTooltip: isRegisteredLegalAddress ? 'This is already an HQ' : undefined,
    label: 'Make HQ',
    onClick: () => setAssignHQModalOpen(true),
    icon: 'zicon-hq'
  };

  const items: MenuItemType[] = [
    ...(canArchiveLocation ? [archiveLocationOption] : []),
    ...(canUnarchiveLocation ? [unarchiveLocationOption] : []),
    ...(canEdit ? [makeHQOption] : [])
  ];

  return (
    <>
      <ArchiveLocationModal
        accountId={accountId}
        isOpen={isArchiveLocationModalOpen}
        locationId={locationId}
        locationName={locationName}
        onClose={() => setIsArchiveLocationModalOpen(false)}
      />
      <UnarchiveLocationModal
        accountId={accountId}
        isOpen={isUnarchiveLocationModalOpen}
        locationId={locationId}
        locationName={locationName}
        onClose={() => setIsUnarchiveLocationModalOpen(false)}
      />
      <UpdateAccountHeadquartersModal
        accountId={accountId}
        companyName={companyName}
        isOpen={isAssignHQModalOpen}
        locationId={locationId}
        onClose={() => setAssignHQModalOpen(false)}
      />
      <ContextMenu inline={false} items={items} />
    </>
  );
};

export default AccountLocationsActions;
