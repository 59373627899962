import { type FC, useMemo, useState } from 'react';

import Search from '@zen/Components/Search';
import { Table } from '@zen/DesignSystem';
import { ConnectionSourceTargetEnum } from '@zen/graphql/types.generated';
import { usePagination } from '@zen/utils/hooks/pagination';
import useUrlFilters from '@zen/utils/hooks/useUrlFilters';

import type { GetAccountConnectionsQueryResult, GetAccountConnectionsQueryVariables } from '../graphql';
import { useGetAccountConnectionsQuery } from '../graphql';
import type { AccountConnectionsSummary } from '../types';
import AccountConnectionFilters from './AccountConnectionFilters';
import getColumnsConfiguration from './accountConnectionsTableConfiguration';
import AddConnectionButton from './AddConnectionButton';

const initialFilters = {
  connectionSourceTarget: ConnectionSourceTargetEnum.BOTH
};

interface Props {
  accountId: string;
}

const AccountConnections: FC<Props> = ({ accountId }) => {
  const { filters } = useUrlFilters(initialFilters);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const { loading, nodes, paginationInfo, totalCount } = usePagination<
    GetAccountConnectionsQueryResult,
    GetAccountConnectionsQueryVariables,
    AccountConnectionsSummary
  >(
    useGetAccountConnectionsQuery,
    'accountConnections',
    { accountId, accountConnectionsFiltersInput: { searchQuery, ...filters } },
    20,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  );

  const columns = useMemo(() => {
    return getColumnsConfiguration(accountId);
  }, [accountId]);

  return (
    <Table<AccountConnectionsSummary>
      actions={<AddConnectionButton accountId={accountId} />}
      additionalActions={
        <div className="flex space-x-2">
          <Search
            collapsible={true}
            onClear={() => setSearchQuery('')}
            onSubmit={setSearchQuery}
            placeholder="Search connections..."
            size="compact"
            widthClassName="w-48"
          />
          <AccountConnectionFilters />
        </div>
      }
      columns={columns}
      data={nodes}
      emptyText="No connections"
      loading={loading}
      paginationInfo={paginationInfo}
      tableId="accountConnections"
      title="Connections"
      totalCountConfig={{
        totalCount,
        entityName: 'connection'
      }}
    />
  );
};

export default AccountConnections;
