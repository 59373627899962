import cx from 'classnames';
import { range } from 'lodash';
import { type CSSProperties, type FC, PropsWithChildren, useRef } from 'react';

import type { Nullable } from '@zen/utils/typescript';

import Icon from '../../Icon';
import type { RowSpan, TableColumn } from '../types';
import { handleTableCellInteraction } from './helpers';
import type { InteractionType } from './types';

export interface Props {
  className?: string;
  columnConfiguration?: Pick<TableColumn, 'alignment' | 'ellipsis' | 'key' | 'width'> & {
    isPartOfMergeByColumn: boolean;
    onEdit?: () => void;
    rowSpans?: Array<RowSpan>;
  };
  rowIndex: number;
  // skipped by rc-library for colspans, eg. empty data row
  style: CSSProperties;
  tableId: string;
}

const TableCell: FC<PropsWithChildren<Props>> = (props) => {
  const { children, className, columnConfiguration, style, rowIndex, tableId, ...rest } = props;
  const cellElement = useRef<HTMLTableCellElement>(null);

  const { alignment = 'left', ellipsis, key, onEdit, width, rowSpans } = columnConfiguration || {};

  const colKey: Nullable<string> = key ? `col-${key}` : null;
  const cellStyle: CSSProperties = {
    ...style,
    minWidth: width,
    maxWidth: width
  };
  const rowSpan: RowSpan | undefined = rowSpans?.find(({ atIndex }) => atIndex === rowIndex);
  const rowSpanValue: number | undefined = rowSpan?.value;

  const mergedCells: Array<number[]> = rowSpans?.map(({ atIndex, value }) => range(atIndex + 1, atIndex + value)) || [];

  const classNames: string = cx(
    {
      'border-solid border-l-grey-lightest border-l border-r-grey-lightest border-r': rowSpan,
      'merge-column-cell': props.columnConfiguration?.isPartOfMergeByColumn
    },
    'h-12 px-4 py-1 bg-white text-sm text-grey-dark whitespace-nowrap align-middle group first:pl-6 last:pr-6',
    className
  );
  const cellContentClassName: string = cx('flex h-full', {
    'items-start pt-2': alignment === 'top-start',
    'justify-end items-center': alignment === 'right',
    'items-center': alignment === 'left',
    '[&>span]:truncate': ellipsis
  });

  const skipCell: boolean = mergedCells.some((cells: number[]) => cells.includes(rowIndex));

  if (skipCell) {
    return null;
  }

  const getInteractionProps = (interactionType: InteractionType) => {
    return {
      element: cellElement.current,
      interactionType,
      rowIndex,
      tableId
    };
  };

  const handleMouseEnter = (): void => handleTableCellInteraction(getInteractionProps('hover'));
  const handleMouseLeave = (): void => handleTableCellInteraction(getInteractionProps('blur'));

  return (
    <td
      {...rest}
      ref={cellElement}
      className={classNames}
      data-component="table-cell"
      data-testid={colKey}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      rowSpan={rowSpanValue}
      style={cellStyle}
    >
      <div className={cellContentClassName}>
        {children}
        {onEdit && (
          <div className="ml-2 text-sm duration-500 opacity-0 cursor-pointer group-hover:opacity-100" onClick={onEdit}>
            <Icon icon="zicon-edit" />
          </div>
        )}
      </div>
    </td>
  );
};

export type { Props as TableCellProps };

export default TableCell;
