import type { FC } from 'react';

import { Form, FormButtons, FormInstance } from '@zen/Components';
import { FormCostTrackingLocationSelect } from '@zen/CostTracking';
import ApplicabilityInput from '@zen/CostTracking/ChargeForm/components/ApplicabilityInput';
import { Button } from '@zen/DesignSystem';
import { useRateCardContext } from '@zen/RateCards/RateCardContext';
import RateCardChargeFormItems from '@zen/RateCards/RateCardForm/components/RateCardChargeFormItems';
import type { RateCardCharge } from '@zen/RateCards/reducer';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import { validationSchema } from './freightCharge.validation';
import { freightApplicabilityOptions, initialValues, preparePayload } from './helpers';
import { Applicability, type FreightChargeFormInitialValues, type FreightChargeFormValues } from './types';

interface Props {
  onCancel: () => void;
  onSubmit: (values: RateCardCharge[]) => void;
  onSuccess: () => void;
}

const FreightChargeForm: FC<Props> = (props) => {
  const { onSubmit, onSuccess, onCancel } = props;
  const { setFreightCharges } = useRateCardContext();

  const handleSubmit = (values: FreightChargeFormValues): Promise<IOkOrErrorResult> => {
    const freightChargePayload = preparePayload(values);

    onSubmit(freightChargePayload);
    setFreightCharges(freightChargePayload);

    return Promise.resolve({ ok: {}, error: null });
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<FreightChargeFormInitialValues>) => {
    return (
      <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Set charges">
        <Button onClick={onCancel} variant="ghost">
          Cancel
        </Button>
      </FormButtons>
    );
  };

  return (
    <Form
      formButtons={renderFormButtons}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      validationSchema={validationSchema}
    >
      {({ values }: FormInstance<FreightChargeFormValues>) => {
        const { chargeItems } = values;

        return (
          <>
            <div className="flex mb-2 gap-x-4 pr-14">
              <FormCostTrackingLocationSelect label="Port of origin" name="fromLocation" />
              <FormCostTrackingLocationSelect label="Port of destination" name="toLocation" />
            </div>
            <ApplicabilityInput
              isMulti={true}
              isOptional={true}
              label="I want to add charges for this lane at:"
              options={freightApplicabilityOptions}
            />
            <RateCardChargeFormItems chargeItems={chargeItems} chargeTypeApplicability={Applicability.FREIGHT} />
          </>
        );
      }}
    </Form>
  );
};

export type { Props as FreightChargeFormProps };

export default FreightChargeForm;
